// API docs @see https://github.com/yiminghe/async-validator
import { Rules, Rule } from "async-validator";
import { regex } from "@/shared/utils/constants";
import { isValidPhoneNumber } from "libphonenumber-js";
import { i18nTranslate } from "@/plugins/i18n";

/** Current implementation returns "A_" when no item is selected from the enum. (A_ == null) */
export const NULL_ENUM_VALUE = "A_";

/**
 * A method to parse current implementation of `null` in GQL Enum.
 * @param value The value to parse in either the EnumType or string.
 */
const parseGqlEnum = <T>(value: T): T | null => {
  const strVal = String(value);

  if (strVal === "" || strVal === NULL_ENUM_VALUE) {
    return null;
  }

  return value;
};

/**
 * A custom Form item validator for checking GQL Enum values for the "A_" workaround.
 */
export const gqlEnumValidator = async (_rule: Rule, value: string) => {
  if (!parseGqlEnum(value)) {
    return Promise.reject();
  }

  return Promise.resolve();
};

export const passwordRequirementsValidator = async (
  _rule: Rule,
  value: string
) => {
  if (value.length < 8) {
    return Promise.reject("Password cannot be less than 8 characters.");
  }

  if (regex.entirelyNumeric.test(value)) {
    return Promise.reject("Password cannot be entirely numeric.");
  }

  return Promise.resolve();
};

export const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
};

export const makeRequiredRule = (
  errorMessage: string,
  type: "string" | "array" | "number" | "boolean" | "object" = "string",
  validateGraphQlNullEnum = false
): {
  type: "string" | "array" | "number" | "boolean" | "object";
  message: string;
  required: boolean;
  validator?: (_rule: Rule, value: string) => Promise<void>;
} => {
  const rule = {
    type,
    required: true,
    message: errorMessage,
  };

  // Add custom validator
  Object.assign(
    rule,
    validateGraphQlNullEnum ? { validator: gqlEnumValidator } : null,
    /**
     * Requires a boolean (i.e. checkbox) to be checked (true) in order to be valid.
     * From: https://github.com/ant-design/ant-design/issues/8261#issuecomment-439833733
     *
     * Explanation:
     * if `value` is `false`, it would be casted to `undefined` (falsy value),
     * which would trigger the error since `required` is `true`.
     */
    type === "boolean" ? { transform: (value) => value || undefined } : null
  );

  return rule;
};

/** Regular function for specified checking of regex string */
export const isRegexMatch = (
  text: string | undefined,
  regexStr: string
): boolean => {
  const regexObject = new RegExp(regexStr);
  let isValid = false;

  if (regexObject.test(text ?? "")) {
    isValid = true;
  } else {
    isValid = false;
  }
  return isValid;
};

export const makeRegexRule = (
  errorMessage: string,
  regexString: string
): {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  message: string;
} => {
  return {
    asyncValidator: (rule, value) => {
      return new Promise<void>((resolve, reject) => {
        const regexObject = new RegExp(regexString);
        if (regexObject.test(value)) {
          resolve();
        } else {
          reject(new Error(errorMessage));
        }
      });
    },
    message: errorMessage,
  };
};

export const makeEmailRule = (
  errorMessage: string
): {
  // eslint-disable-next-line no-unused-vars
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  message: string;
} => {
  return makeRegexRule(errorMessage, regex.email);
};

export const makePasswordRequirementsRule = () => {
  return {
    type: "string",
    required: true,
    validator: passwordRequirementsValidator,
  };
};

/** A form validator that checks if the input is a **valid** mobile number, prefixed with "+". */
export const makePhoneNumberRule = (errorMessage?: string) => {
  return {
    type: "string",
    message:
      errorMessage ||
      i18nTranslate(
        "Please enter a valid phone number in this format: [+][Country Code][Your Number]."
      ),
    validator: (_rule: Rules, value: string): Promise<void> => {
      // Treat this field as optional. Add makeRequiredField in rules instead.
      if (!value) {
        return Promise.resolve();
      }

      if (value.charAt(0) !== "+" || !isValidPhoneNumber(value)) {
        return Promise.reject(errorMessage);
      }

      return Promise.resolve();
    },
  };
};

/** Make rule for array input types (ie. checkboxes) that all options should be checked */
export const makeAllSelectedRule = (errorMessage: string, length: number) => {
  return {
    type: "array",
    required: true,
    message: errorMessage,
    validator: (_rule: Rules, value: unknown[]): Promise<void> => {
      if (value.length < length) {
        return Promise.reject(errorMessage);
      }

      return Promise.resolve();
    },
  };
};

// ========== Generic Custom Validator ==========

export type MakeQueryValidatorRuleOptions<T> = {
  type: string;
  required?: boolean;
  trigger?: "change" | "blur";
  /**
   * Pass a function that will check for response (can be from API, async)
   * Return a string for the error message, else return `null` if valid.
   */
  customValidatorHook: (value: T) => Promise<string | null>;
};

export const makeGraphQLValidatorRule = <T>(
  options: MakeQueryValidatorRuleOptions<T>
) => {
  return {
    type: options.type,
    required: options.required || false,
    trigger: options.trigger || "blur",
    validator: async (_rule: Rules, value: T): Promise<void> => {
      if (options.required && !value) {
        Promise.reject(i18nTranslate("This field is required."));
      }

      // Check if returned a message if has error
      const errorMessage = await options.customValidatorHook(value);
      if (errorMessage) {
        return Promise.reject(errorMessage);
      }

      Promise.resolve();
    },
  };
};

export const makeValidUrlRule = (errorMessage?: string) => {
  return {
    type: "string",
    message: errorMessage || i18nTranslate("Please enter a valid URL."),
    validator: (_rule: Rules, value: string): Promise<void> => {
      // Treat this field as optional. Add makeRequiredField in rules instead.
      if (!value) {
        return Promise.resolve();
      }

      if (!isValidUrl(value)) {
        return Promise.reject(errorMessage);
      }

      return Promise.resolve();
    },
  };
};
