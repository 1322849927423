import { computed } from "vue";
import { useGlobalModal } from "@/shared/composables/useGlobalModal";

/** Key for the localStorage to determine user's first login */
export const LOCAL_STORE_FIRST_LOGIN = "Kamilas4am-first-login";

export const useHowToOrder = () => {
  const { openGlobalModal } = useGlobalModal("HOW_TO_ORDER");

  const getFirstLoginValue = localStorage.getItem(LOCAL_STORE_FIRST_LOGIN);

  const setFirstLoginValue = () =>
    localStorage.setItem(LOCAL_STORE_FIRST_LOGIN, "true");

  const isFirstLogin = computed(
    () => !getFirstLoginValue || getFirstLoginValue !== "true"
  );

  const checkIfFirstLogin = () => {
    if (isFirstLogin.value) {
      openGlobalModal();
      setFirstLoginValue();
    }
  };

  return {
    isFirstLogin,
    checkIfFirstLogin,
  };
};
