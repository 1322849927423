import pickBy from "lodash/pickBy";
import isNil from "lodash/isNil";

/**
 * Remove object properties that is undefined or null
 *
 * @param obj
 * @returns
 */
export const compactObject = (obj) => pickBy(obj, (value) => !isNil(value));
