import { App } from "@vue/runtime-core";
import VueAuthenticate from "vue-authenticate-2";
import axios from "axios";
import { config } from "@/shared/utils/config";

export const providers = {
  google: {
    name: "google",
    label: "google",
    url: null, // set to null, it's continuing authentication even method called is only link()
    authorizationEndpoint: "https://accounts.google.com/o/oauth2/auth",
    oauthType: "2.0",
    responseType: "code",
    clientId: config.authGoogleClientId,
    redirectUri: config.authGoogleRedirectUri,
    defaultUrlParams: ["client_id", "response_type", "redirect_uri"],
  },
};

export const useVueAuthenticatePlugin = (app: App): App => {
  return app.use(VueAuthenticate, {
    baseUrl: config.authBaseUrl, // Your API domain - not needed for non oauth version 1
    axios: axios.create({}),
    providers,
  });
};
