
import LayoutAuthenticated from "@/shared/components/Layouts/LayoutAuthenticated.vue";
import LayoutNonAuthenticated from "@/shared/components/Layouts/LayoutNonAuthenticated.vue";
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import VErrorBoundary from "vue-error-boundary";
import { useStore } from "vuex";
import routePaths from "@/web/router/routePaths";
import routeNames from "@/web/router/routeNames";
import HowToOrderModal from "@/shared/components/HowToOrderModal.vue";
import AskForQuotationModal from "@/shared/components/AskForQuotationModal.vue";
import LearnMoreModal from "@/shared/components/LearnMoreModal.vue";
import FileDownloaderModal from "@/shared/components/FileDownloaderModal.vue";

export default defineComponent({
  components: {
    LayoutAuthenticated,
    LayoutNonAuthenticated,
    VErrorBoundary,
    HowToOrderModal,
    AskForQuotationModal,
    LearnMoreModal,
    FileDownloaderModal,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const isAuthenticated = computed(() => store.state.auth.isAuthenticated);

    return {
      t,
      isAuthenticated,
      routePaths,
      routeNames,
    };
  },
});
