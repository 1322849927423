import {
  actionMutationFactory,
  resetStateActionMutationFactory,
} from "@/shared/utils/storeUtils";
import { RootState } from "@/web/store";
import { compactObject } from "@/shared/utils/object";
import { AuthenticateViaGoogle_authenticateViaGoogle_Authentication_user_subscriptionPlan } from "@/api/authentication/__generated__/AuthenticateViaGoogle";

export type AuthState = {
  isAuthenticated: boolean;
  token: string;
  expiry: string;
  userId: string | number;
  email: string;
  username: string;
  name: string;
  subscriptionPlan: AuthenticateViaGoogle_authenticateViaGoogle_Authentication_user_subscriptionPlan | null;
  cartItemCount: number | null;
};

export type AuthPayload = AuthState;

const authInitialState = {
  isAuthenticated: false,
  token: "",
  expiry: "",
  userId: "",
  email: "",
  username: "",
  name: "",
  subscriptionPlan: null,
  cartItemCount: null,
};

const authUpdateActionMutation = actionMutationFactory<
  AuthState,
  AuthPayload,
  RootState
>("authUpdate", (state, payload) => {
  /**
   * Remove undefined and/or null values first before replacing the auth state
   */
  Object.assign(state, compactObject(payload));
});

const authResetActionMutation = resetStateActionMutationFactory<
  AuthState,
  RootState
>("logoutSuccess", authInitialState);

export const authStore = {
  state: {
    ...authInitialState,
  },
  actions: {
    ...authResetActionMutation.actions,
    ...authUpdateActionMutation.actions,

    authSubscriptionPlanUpdate({ commit }, subscriptionPlan) {
      commit("SET_SUBSCRIPTION_PLAN", subscriptionPlan);
    },
  },
  mutations: {
    ...authResetActionMutation.mutations,
    ...authUpdateActionMutation.mutations,

    SET_SUBSCRIPTION_PLAN(state, payload) {
      state.subscriptionPlan = payload;
    },
  },
};
