import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ask-for-quotation-modal__wrapper" }
const _hoisted_2 = { class: "ask-for-quotation-modal__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    width: "760px",
    visible: _ctx.isGlobalModalVisible,
    footer: null,
    onCancel: _ctx.closeGlobalModal,
    "destroy-on-close": "",
    "mask-closable": "",
    closable: false,
    class: "ask-for-quotation-modal",
    centered: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_AppIcon, {
          name: "emphasisCart",
          class: "ask-for-quotation-modal__icon"
        }),
        _createVNode(_component_a_typography_title, { level: 3 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Before You Checkout...")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_typography_paragraph, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t(
            "Please tell us how many videos and the add-ons you will be ordering. If you already discussed these with our team, please provide the same details as you intend to keep."
          )), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_typography_paragraph, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t(
            "If you've already paid for the package, you won't be asked to pay again unless you purchase more add-ons."
          )), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_button, {
            type: "secondary",
            onClick: _ctx.handleCancel,
            class: "ask-for-quotation-modal__actions__btn",
            loading: _ctx.loading,
            disabled: _ctx.loading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Later")), 1)
            ]),
            _: 1
          }, 8, ["onClick", "loading", "disabled"]),
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: _ctx.handleConfirm,
            class: "ask-for-quotation-modal__actions__btn",
            loading: _ctx.loading,
            disabled: _ctx.loading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Proceed")), 1)
            ]),
            _: 1
          }, 8, ["onClick", "loading", "disabled"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible", "onCancel"]))
}