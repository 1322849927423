import gql from "graphql-tag";

export const updateBusinessProfileGql = gql`
  mutation UpdateBusinessProfile($input: UpdateBusinessProfileInput!) {
    updateBusinessProfile(input: $input) {
      ... on GenericSuccess {
        success
        message
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage

          ... on InvalidInputError {
            field
          }
        }
      }
    }
  }
`;
