import { AuthenticateViaEmailInput } from "@/../__generated__/globalTypes";
import { authenticateViaEmailGql } from "@/api/authentication/authenticateViaEmail";
import { authenticateViaGoogleGql } from "@/api/authentication/authenticateViaGoogle";
import { logoutGql } from "@/api/authentication/logout";
import {
  AuthenticateViaEmail,
  AuthenticateViaEmailVariables,
  AuthenticateViaEmail_authenticateViaEmail_Authentication,
} from "@/api/authentication/__generated__/AuthenticateViaEmail";
import {
  AuthenticateViaGoogle,
  AuthenticateViaGoogleVariables,
  AuthenticateViaGoogle_authenticateViaGoogle_Authentication,
} from "@/api/authentication/__generated__/AuthenticateViaGoogle";
import {
  Logout,
  Logout_logout_GenericSuccess,
} from "@/api/authentication/__generated__/Logout";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { useSocialAuthProvider } from "@/shared/composables/useSocialAuthProvider";
import {
  analyticsTrackLogin,
  analyticsTrackLogout,
} from "@/shared/utils/analytics";
import { config } from "@/shared/utils/config";
import { apiErrorCodes } from "@/shared/utils/constants";
import {
  parseGqlResponse,
  ParseGqlResponse,
} from "@/shared/utils/graphql/responseParser";
import { createRedirectedRoute } from "@/shared/utils/routerUtils";
import routeNames from "@/web/router/routeNames";
import isEmpty from "lodash/isEmpty";
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { makeToast } from "@/shared/utils/toast";
import { logRocketIdentify } from "@/shared/utils/logrocket";
import { useApolloClient } from "@vue/apollo-composable";
import routePaths from "@/web/router/routePaths";
import { businessProfileGql } from "@/api/kyc/businessProfile";

export type LoginMethod = "email-password" | "google";

export type LoginOptions = {
  method: LoginMethod;
  authenticateViaEmailInput?: AuthenticateViaEmailInput;
};

export const useAuthentication = () => {
  const store = useStore();
  const router = useRouter();
  const { client } = useApolloClient();

  const {
    mutate: authenticateViaGoogleMutate,
    loading: authenticateViaGoogleLoading,
  } = useCustomMutation<AuthenticateViaGoogle, AuthenticateViaGoogleVariables>(
    authenticateViaGoogleGql,
    {
      refetchQueries: [{ query: businessProfileGql }],
      awaitRefetchQueries: true,
    }
  );

  const {
    mutate: authenticateViaEmailMutate,
    loading: authenticateViaEmailLoading,
  } = useCustomMutation<AuthenticateViaEmail, AuthenticateViaEmailVariables>(
    authenticateViaEmailGql,
    {
      refetchQueries: [{ query: businessProfileGql }],
      awaitRefetchQueries: true,
    }
  );

  const { mutate: logoutMutate, loading: logoutLoading } =
    useCustomMutation<Logout>(logoutGql);

  const { authenticate, authenticatingProvider } = useSocialAuthProvider();

  const handleLogin = async (options: LoginOptions = { method: "google" }) => {
    let response: any = null;
    let parsedResponse:
      | ParseGqlResponse<AuthenticateViaGoogle_authenticateViaGoogle_Authentication>
      | ParseGqlResponse<AuthenticateViaEmail_authenticateViaEmail_Authentication>
      | null = null;

    if (options.method === "google") {
      const googleResponse = await authenticate("google");

      response = await authenticateViaGoogleMutate({
        input: {
          authorizationCode: googleResponse.code,
          redirectUri: config.authGoogleRedirectUri,
        },
      });

      parsedResponse =
        parseGqlResponse<AuthenticateViaGoogle_authenticateViaGoogle_Authentication>(
          "Authentication",
          response,
          apiErrorCodes.INVALID_INPUT
        );
    } else if (
      options.method === "email-password" &&
      options.authenticateViaEmailInput
    ) {
      response = await authenticateViaEmailMutate({
        input: options.authenticateViaEmailInput,
      });

      parsedResponse =
        parseGqlResponse<AuthenticateViaEmail_authenticateViaEmail_Authentication>(
          "Authentication",
          response,
          apiErrorCodes.INVALID_INPUT
        );
    }

    console.log("parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse?.error?.errors) || !response) {
      // Custom toast message for INVALID_INPUT
      const invalidInputError = parsedResponse?.error?.errors.find(
        (error) => String(error.code) == String(apiErrorCodes.INVALID_INPUT)
      );

      // Omit "Invalid input. " in the beginning.
      if (invalidInputError) {
        makeToast(
          "error",
          "Error",
          invalidInputError?.message.replace("Invalid input. ", "")
        );
      }

      throw new Error(`failed to authenticate via ${options.method}`);
    }

    const user = parsedResponse?.data?.user;
    const authToken = parsedResponse?.data?.authToken;

    if (user?.id && authToken?.token) {
      await store.dispatch("authUpdate", {
        isAuthenticated: true,
        token: authToken.token,
        expiry: authToken.expiry,
        userId: user.id,
        email: user.email,
        username: user.username,
        name: user.name,
        subscriptionPlan: user.subscriptionPlan,
        cartItemCount: user.cartItemCount,
      });

      logRocketIdentify(user.id);
    }

    analyticsTrackLogin();
  };

  const handleLogout = async () => {
    const logoutResponse = await logoutMutate();

    const parsedResponse = parseGqlResponse<Logout_logout_GenericSuccess>(
      "GenericSuccess",
      logoutResponse
    );

    console.log("handleLogout:parsedResponse", parsedResponse);

    /**
     * Dispatch logout action regardless of the response
     */
    await store.dispatch("logoutSuccess");

    await router.push(
      createRedirectedRoute({ name: routeNames.login }, routePaths.home)
    );

    // Clear all existing data and re-fetch for clean state (next user)
    client.clearStore();

    analyticsTrackLogout();
  };

  const isAuthenticated = computed<boolean>(
    () => store.state.auth.isAuthenticated && store.state.auth.token.length
  );

  return {
    handleLogin,
    loginLoading: computed(
      () =>
        authenticateViaGoogleLoading.value ||
        authenticateViaEmailLoading.value ||
        !!authenticatingProvider.value
    ),
    handleLogout,
    logoutLoading,
    isAuthenticated,
  };
};
