import { config } from "@/shared/utils/config";

export type TiktokPixelStandardEventName =
  | "AddPaymentInfo"
  | "AddToCart"
  | "AddToWishlist"
  | "ClickButton"
  | "CompletePayment"
  | "CompleteRegistration"
  | "Contact"
  | "Download"
  | "InitiateCheckout"
  | "PlaceAnOrder"
  | "Search"
  | "SubmitForm"
  | "Subscribe"
  | "ViewContent";

/** From: https://ads.tiktok.com/marketing_api/docs?rid=5ipocbxyw8v&id=1701890973258754 */
export type TiktokPixelEventParameters = Partial<{
  content_id: string | number;
  contents: (string | number)[];
  content_name: string;
  content_type: "product" | "product_group";
}>;

/** Disable Tiktok Pixel if id is not provided */
const ENABLE_TIKTOK_PIXEL = !!config.tiktokPixelId;

export const tiktokPixelTrackEvent = (
  eventName: TiktokPixelStandardEventName,
  eventProperties?: TiktokPixelEventParameters
): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _globalThis = globalThis as any;

  // If tracking is disabled, or fbq has not loaded in window
  if (!ENABLE_TIKTOK_PIXEL || !_globalThis.ttq) {
    return;
  }

  _globalThis.ttq.track(eventName, eventProperties);
};

export const tiktokPixelTrackPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _globalThis = globalThis as any;

  if (!ENABLE_TIKTOK_PIXEL || !_globalThis.ttq) {
    return;
  }

  _globalThis.ttq.page();
};
