import { message as messageRaw } from "ant-design-vue";
import isString from "lodash/isString";
import { h } from "vue";
import {
  CommonAlertTypes as MessageTypes,
  commonAlertIconsMap as customMessageIcon,
} from "@/shared/utils/alert";

/**
 * This is the common message handler
 *
 * @param type
 * @param configOrContent
 * @returns
 */
export const open = (type: MessageTypes, configOrContent) => {
  return messageRaw[type]({
    ...(isString(configOrContent)
      ? { content: configOrContent }
      : configOrContent),
    icon: () => h(customMessageIcon[type]),
  });
};

/**
 * To maintain consistency with ant's message components
 * Use message["type"]() method
 */
const customMessage: {
  [key in MessageTypes]: (any) => ReturnType<typeof open>;
} = {
  [MessageTypes["info"]]: (configOrContent) =>
    open(MessageTypes["info"], configOrContent),
  [MessageTypes["success"]]: (configOrContent) =>
    open(MessageTypes["success"], configOrContent),
  [MessageTypes["error"]]: (configOrContent) =>
    open(MessageTypes["error"], configOrContent),
  [MessageTypes["warning"]]: (configOrContent) =>
    open(MessageTypes["warning"], configOrContent),
};

export default customMessage;
