import gql from "graphql-tag";

export const creatorContentSubmissionsGql = gql`
  query CreatorContentSubmissions($input: CreatorContentSubmissionsInput!) {
    creatorContentSubmissions(input: $input) {
      ... on CreatorContentSubmissionResults {
        __typename
        results {
          id
          contentType
          photoThumbnail
          photo
          brand
          creatorName
          photoPrice
          videoPrice
          video
          publicCreatorLink
          videoDuration
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
        totalCount
      }

      ... on ResponseErrors {
        errors {
          code
          displayMessage
          message
        }
      }
    }
  }
`;

export const creatorContentSubmissionsTotalPriceGql = gql`
  query CreatorContentSubmissionsTotalPrice(
    $input: CreatorContentSubmissionsInput!
  ) {
    creatorContentSubmissions(input: $input) {
      ... on CreatorContentSubmissionResults {
        __typename
        totalPrice
      }

      ... on ResponseErrors {
        errors {
          code
          displayMessage
          message
        }
      }
    }
  }
`;
