import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "layout-footer__links" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_NewsletterForm = _resolveComponent("NewsletterForm")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_PageLayout = _resolveComponent("PageLayout")!
  const _component_a_layout_footer = _resolveComponent("a-layout-footer")!

  return (_openBlock(), _createBlock(_component_a_layout_footer, { class: "layout-footer" }, {
    default: _withCtx(() => [
      _createVNode(_component_PageLayout, { class: "layout-footer__container overflow-hidden" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_row, {
            gutter: [40, 40],
            align: "middle",
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                span: 24,
                md: 12
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_space, {
                    direction: "vertical",
                    size: 28
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createVNode(_component_a_typography_title, {
                          level: 3,
                          class: "mb-2"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("Connect with us")), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_space, {
                          size: "middle",
                          wrap: ""
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socialLinks, (link) => {
                              return (_openBlock(), _createBlock(_component_a_button, {
                                type: "primary",
                                shape: "circle",
                                class: "layout-footer__btn-social",
                                key: link.url,
                                onClick: ($event: any) => (_ctx.openInNewTab(link.url))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_AppIcon, {
                                    name: link.appIconName
                                  }, null, 8, ["name"])
                                ]),
                                _: 2
                              }, 1032, ["onClick"]))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", null, [
                        _createVNode(_component_a_typography_title, {
                          level: 3,
                          class: "mb-2"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("Quick Links")), 1)
                          ]),
                          _: 1
                        }),
                        _createElementVNode("div", _hoisted_1, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.footerLinks, (link) => {
                            return (_openBlock(), _createBlock(_component_a_button, {
                              key: link.url,
                              type: "text",
                              size: "small",
                              class: "layout-footer__btn-link normal-letter-spacing",
                              onClick: ($event: any) => (_ctx.openInNewTab(link.url))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(link.label), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          }), 128))
                        ])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, {
                span: 24,
                md: 12
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_typography_title, { level: 3 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Subscribe to our newsletter")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_typography_paragraph, { class: "fine" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Sign up to get the latest news, promos and more!")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_NewsletterForm),
                  _createVNode(_component_a_typography_paragraph, { class: "pt-8 fine small" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Copyright {year}. {merchantBrand}. All rights reserved.", {
                year: _ctx.config.copyrightYear,
                merchantBrand: _ctx.config.merchantBrand,
              })), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}