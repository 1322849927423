import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import {
  BusinessProfile,
  BusinessProfile_businessProfile_BusinessProfile,
} from "@/api/kyc/__generated__/BusinessProfile";
import {
  UpdateBusinessProfile,
  UpdateBusinessProfileVariables,
  UpdateBusinessProfile_updateBusinessProfile_GenericSuccess,
} from "@/api/kyc/__generated__/UpdateBusinessProfile";
import { businessProfileGql } from "@/api/kyc/businessProfile";
import { updateBusinessProfileGql } from "@/api/kyc/updateBusinessProfile";
import { recommendedVideoIdeasGql } from "@/api/video/recommendedVideoIdeas";
import { apiErrorCodes } from "@/shared/utils/constants";
import { useQuery } from "@vue/apollo-composable";
import { computed, ref } from "vue";
import { UsersBusinessProfilePendingStepChoices } from "../../../__generated__/globalTypes";
import { parseGqlResponse } from "../utils/graphql/responseParser";
import { WatchQueryFetchPolicy } from "@apollo/client";

export const checkInitialKycRegitrationDone = <
  T extends Pick<
    BusinessProfile_businessProfile_BusinessProfile,
    "nickname" | "industry" | "industryOther"
  >
>(
  businessProfile?: T | null
) => {
  return (
    // Nickname provided, AND
    !!businessProfile?.nickname &&
    // Industry provided
    (!!businessProfile?.industryOther || !!businessProfile?.industry)
  );
};

export const useBusinessProfile = (
  options: {
    fetchBusinessProfileOnMount?: boolean;
    fetchPolicy?: WatchQueryFetchPolicy;
    hideErrors?: boolean;
  } = {
    fetchBusinessProfileOnMount: true,
    hideErrors: false,
  }
) => {
  const queryEnabled = ref(options.fetchBusinessProfileOnMount ?? false);

  // QUERY
  const {
    result: businessProfileResult,
    loading: businessProfileLoading,
    refetch: fetchBusinessProfile,
  } = useQuery<BusinessProfile>(businessProfileGql, {}, () => ({
    fetchPolicy: options.fetchPolicy ?? "cache-and-network",
    enabled: queryEnabled.value,
  }));

  const businessProfile = computed(() => {
    const parsedResponse =
      parseGqlResponse<BusinessProfile_businessProfile_BusinessProfile>(
        "BusinessProfile",
        businessProfileResult.value,
        // FIXME: For workaround only on missing header on expired token. See: #3gaydgp
        apiErrorCodes.INVALID_AUTH_TOKEN
      );

    return parsedResponse.data;
  });

  const kycStarted = computed(() => !!businessProfile.value);
  const kycCurrentStep = computed(() => businessProfile.value?.pendingStep);
  const kycDone = computed(
    () => kycCurrentStep.value === UsersBusinessProfilePendingStepChoices.DONE
  );
  const kycHasRegistered = computed(() =>
    checkInitialKycRegitrationDone(businessProfile.value)
  );

  // MUTATIONS
  const {
    mutate: updateBusinessProfileMutate,
    loading: updateBusinessProfileLoading,
  } = useCustomMutation<UpdateBusinessProfile, UpdateBusinessProfileVariables>(
    updateBusinessProfileGql,
    {
      refetchQueries: [
        { query: businessProfileGql, fetchPolicy: "network-only" },
        { query: recommendedVideoIdeasGql, fetchPolicy: "network-only" },
      ],
      awaitRefetchQueries: true,
    }
  );

  const saveBusinessProfile = async (input: UpdateBusinessProfileVariables) => {
    const response = await updateBusinessProfileMutate(input);

    const parsedResponse =
      parseGqlResponse<UpdateBusinessProfile_updateBusinessProfile_GenericSuccess>(
        "GenericSuccess",
        response
      );

    if (parsedResponse.error) {
      throw parsedResponse.error;
    }

    return parsedResponse.data?.success;
  };

  return {
    kycStarted,
    kycHasRegistered,
    kycDone,
    kycCurrentStep,
    businessProfile,
    businessProfileLoading,
    fetchBusinessProfile: async () => {
      queryEnabled.value = true;
      return await fetchBusinessProfile();
    },
    updateBusinessProfileMutate,
    updateBusinessProfileLoading,
    saveBusinessProfile,
  };
};
