
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import logo from "@/assets/logo.png";
import facebook from "@/assets/socials/facebook.svg";
import twitter from "@/assets/socials/twitter.svg";
import instagram from "@/assets/socials/instagram.svg";
import linkedin from "@/assets/socials/linkedin.svg";
import tiktok from "@/assets/socials/tiktok.svg";
import email from "@/assets/socials/email.svg";
import imagePreviewMask from "@/assets/image-preview-mask.svg";
import clock from "@/assets/icons/clock.svg";
import star from "@/assets/icons/star.svg";
import starOutlined from "@/assets/icons/star-outlined.svg";
import playButton from "@/assets/icons/play-button.svg";
import playButtonWhite from "@/assets/icons/play-button-white.svg";
import location from "@/assets/icons/location.svg";
import tags from "@/assets/icons/tags.svg";
import crownRed from "@/assets/icons/crown-red.svg";
import starPink from "@/assets/icons/star-pink.svg";
import cameraBlue from "@/assets/icons/camera-blue.svg";
import restricted from "@/assets/icons/restricted.svg";
import checkmarkBlue from "@/assets/icons/checkmark-blue.svg";
import checkmarkGreen from "@/assets/icons/checkmark-green.svg";
import trash from "@/assets/icons/trash.svg";
import trashGray from "@/assets/icons/trash-gray.svg";
import trashWhite from "@/assets/icons/trash-white.svg";
import trashRed from "@/assets/icons/trash-red.svg";
import trashBlue from "@/assets/icons/trash-blue.svg";
import diamondGold from "@/assets/icons/diamond-gold.svg";
import gemSilver from "@/assets/icons/gem-silver.svg";
import gemBronze from "@/assets/icons/gem-bronze.svg";
import industryBeauty from "@/assets/icons/industry-beauty.svg";
import industryFashion from "@/assets/icons/industry-fashion.svg";
import industryFood from "@/assets/icons/industry-food.svg";
import industryLifestyle from "@/assets/icons/industry-lifestyle.svg";
import phoneEmoji from "@/assets/icons/phone-emoji.svg";
import twinkleEmoji from "@/assets/icons/twinkle-emoji.svg";
import info from "@/assets/icons/info.svg";
import chevron from "@/assets/icons/chevron.svg";
import greyCamcorder from "@/assets/icons/grey-camcorder.svg";
import greyGift from "@/assets/icons/grey-gift.svg";
import phoneCircle from "@/assets/icons/phone-circle.svg";
import share from "@/assets/icons/share.svg";
import rate from "@/assets/icons/rate.svg";
import emphasisCart from "@/assets/icons/emphasis-cart.svg";
import logout from "@/assets/icons/logout.svg";
import cartPlus from "@/assets/icons/cart-plus.svg";
import cartPlusWhite from "@/assets/icons/cart-plus-white.svg";
import videoPlayButtonOverlay from "@/assets/icons/video-play-button-overlay.svg";

/** Object mapping of imported images
 * TODO: Add your images here
 */
const APP_ICONS = {
  logo,
  phoneEmoji,
  twinkleEmoji,
  info,
  chevron,
  phoneCircle,
  share,
  rate,
  emphasisCart,
  logout,
  cartPlus,
  cartPlusWhite,
  videoPlayButtonOverlay,

  // Performer Stats and Badges
  clock,
  star,
  starOutlined,
  playButton,
  playButtonWhite,
  location,
  tags,
  crownRed,
  starPink,
  cameraBlue,
  restricted,
  checkmarkBlue,
  checkmarkGreen,
  trash,
  trashGray,
  trashWhite,
  trashRed,
  trashBlue,
  diamondGold,
  gemSilver,
  gemBronze,

  // Industries
  industryBeauty,
  industryFashion,
  industryFood,
  industryLifestyle,

  // Socials
  facebook,
  twitter,
  instagram,
  linkedin,
  tiktok,
  email,
  "image-preview-mask": imagePreviewMask,

  // Grey icons
  greyCamcorder,
  greyGift,
};

/** Provides code autocompletion for icon names */
export type AppIconName = keyof typeof APP_ICONS;

export const getAppIcon = (iconName: AppIconName) => {
  return APP_ICONS[iconName];
};

export default defineComponent({
  props: {
    name: {
      type: String as PropType<AppIconName>,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
  methods: { getAppIcon },
});
