import sharedRoutePaths from "@/shared/router/routePaths";

export default {
  login: "/login",
  register: "/register",
  registerKyc: "/register-kyc",
  profile: "/profile", // Dashboard
  plans: "/plans",
  cart: "/cart",
  kyc: "/kyc",
  thankYou: "/thank-you",

  // Video Routes
  videoDetails: "/video/:id",
  publicVideoGallery: "/video-gallery",

  // Performer Routes
  publicPerformerDetails: "/model-creator/:id",
  performerDetails: "/performer/:id",
  publicPerformers: "/model-creators",
  performers: "/performers",

  // TODO: Can be removed in the future once it's certain that the feature won't be re-used.
  // See: #865d825tt #865cxfbj5
  performerWorkWithMe: "/model-creator/:id/work-with-me",

  // Job (Performer)
  performerPublicUploadVideo: "/job/upload-video",

  // Quotation (Pricing Calculator)
  quote: "/quote",
  quoteSummary: "/quote/summary",
  quoteInvoice: "/quote/invoice/:uuid",

  // Order : List
  orders: "/orders",
  // Order : Detail
  orderDetail: "/order/:orderId",
  // Order : Detail : Actions
  orderShippingDetails: "/order/:orderId/shipping-details",
  requests: "/order/:orderId/requests",
  reviewRequests: "/order/:orderId/review-requests",
  downloadRequests: "/order/:orderId/download-requests",
  performerSelect: "/order/:orderId/performer-select",
  performerApplications: "/order/:orderId/performer-applications",

  // Creator content
  creatorContentSubmit: "/creator-content/submit",
  creatorContentSearch: "/creator-content/search",
  creatorContentCart: "/creator-content/cart",
  creatorContentSummary: "/creator-content/summary",
  creatorContentThankYou: "/creator-content/thank-you",

  ...sharedRoutePaths,
};
