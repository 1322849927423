
import { defineComponent } from "vue";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import { useTheme } from "@/shared/utils/themes";

export default defineComponent({
  setup() {
    const { isDarkTheme } = useTheme();

    return { isDarkTheme };
  },
  components: { AppIcon },
});
