import gql from "graphql-tag";

export const profileGql = gql`
  query Profile {
    profile {
      ... on User {
        id
        username
        email
        created
        modified
        name
        subscriptionPlan {
          id
          created
          modified
          name
          price
          maxVideoCount
          description
          isActive
          sortWeight
        }
        cartItemCount
        videoCredit {
          id
          numberOfVideos
          expiryDate
        }
      }

      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
