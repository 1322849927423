import { gTagPageViewTrack } from "@/gtag";
import { metaPixelTrackPage } from "@/metaPixel";
import sharedRoutes from "@/shared/router";
import { createRedirectedRoute } from "@/shared/utils/routerUtils";
import { tiktokPixelTrackPage } from "@/tiktokPixelAnalytics";
import NProgress from "nprogress";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "../store";
import routeNames from "./routeNames";
import routePaths from "./routePaths";
import { BusinessProfile_businessProfile_BusinessProfile } from "@/api/kyc/__generated__/BusinessProfile";
import {
  checkInitialKycRegitrationDone,
  useBusinessProfile,
} from "@/shared/composables/useBusinessProfile";
import {
  hasResponseErrorCode,
  parseGqlResponse,
} from "@/shared/utils/graphql/responseParser";
import { HideIfErrorCode } from "@/shared/utils/graphql/errorHandler";
import { apiErrorCodes } from "@/shared/utils/constants";

export const HeaderTypes = {
  AUTH: "AUTH",
  NON_AUTH: "NON_AUTH",
  NO_HEADER: "NO_HEADER",
  CREATOR_CONTENT: "CREATOR_CONTENT",
} as const;
export type HeaderType = typeof HeaderTypes[keyof typeof HeaderTypes];

/**
 * Type Annotations for available Route Meta options
 */
declare module "vue-router" {
  interface RouteMeta {
    /** Requires login. Redirect to `/login` if not yet logged in. */
    requiresAuth?: boolean;
    /** Block already logged in users to route. Useful for ex. in `/login` */
    blockAuthenticated?: boolean;
    /** Force a custom header to use. */
    customHeader?: HeaderType;
  }
}

/**
 * Note: Add `web` prefix to webpackChunkName to prevent overlap with other routes
 * (e.g. webHome)
 */
const webRoutes: Array<RouteRecordRaw> = [
  {
    path: routePaths.home,
    name: routeNames.home,
    component: () =>
      import(/* webpackChunkName: "webHome" */ "../views/Home/Home.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: routePaths.cart,
    name: routeNames.cart,
    component: () =>
      import(/* webpackChunkName: "webCart" */ "../views/Cart/Cart.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: routePaths.profile,
    name: routeNames.profile,
    component: () =>
      import(
        /* webpackChunkName: "webProfile" */ "../views/Profile/Profile.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: routePaths.plans,
    name: routeNames.plans,
    component: () =>
      import(/* webpackChunkName: "webPlans" */ "../views/Plans/Plans.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: routePaths.kyc,
    name: routeNames.kyc,
    component: () =>
      import(/* webpackChunkName: "webKyc" */ "../views/Kyc.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: routePaths.thankYou,
    name: routeNames.thankYou,
    component: () =>
      import(
        /* webpackChunkName: "webThankYou" */ "../views/ThankYou/ThankYou.vue"
      ),
    meta: { requiresAuth: true },
  },

  // Video Routes
  {
    path: routePaths.videoDetails,
    name: routeNames.videoDetails,
    component: () =>
      import(
        /* webpackChunkName: "webVideoDetails" */ "../views/VideoDetails/VideoDetails.vue"
      ),
  },
  {
    path: routePaths.publicVideoGallery,
    name: routeNames.publicVideoGallery,
    component: () =>
      import(
        /* webpackChunkName: "webPublicVideoGallery" */ "../views/PublicVideoGallery/PublicVideoGallery.vue"
      ),
  },

  // Performer Routes
  {
    path: routePaths.publicPerformerDetails,
    name: routeNames.publicPerformerDetails,
    component: () =>
      import(
        /* webpackChunkName: "webPublicPerformerDetails" */ "../views/Performers/PublicPerformerDetails.vue"
      ),
  },
  {
    path: routePaths.performerDetails,
    name: routeNames.performerDetails,
    component: () =>
      import(
        /* webpackChunkName: "webPerformerDetails" */ "../views/Performers/PerformerDetails.vue"
      ),
  },
  {
    path: routePaths.performerSelect,
    name: routeNames.performerSelect,
    component: () =>
      import(
        /* webpackChunkName: "webPerformerSelect" */ "../views/Performers/PerformerSelect.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: routePaths.performerApplications,
    name: routeNames.performerApplications,
    component: () =>
      import(
        /* webpackChunkName: "webPerformerApplications" */ "../views/Performers/PerformerApplications.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: routePaths.publicPerformers,
    name: routeNames.publicPerformers,
    component: () =>
      import(
        /* webpackChunkName: "webPublicPerformers" */ "../views/Performers/PublicPerformers.vue"
      ),
  },
  // FIXME: To remove in future. This is just to route previous /top-performers URL to the new one
  {
    path: "/top-performers",
    name: "old-public-performers",
    redirect: routePaths.publicPerformers,
  },
  {
    path: routePaths.performers,
    name: routeNames.performers,
    component: () =>
      import(
        /* webpackChunkName: "webPerformers" */ "../views/Performers/Performers.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: routePaths.performerWorkWithMe,
    name: routeNames.performerWorkWithMe,
    component: () =>
      import(
        /* webpackChunkName: "webPerformerWorkWithMe" */ "../views/Performers/PerformerWorkWithMe.vue"
      ),
  },
  // FIXME: To remove in future. This is just to route previous /performer-select URL to the new one
  {
    path: "/performer-select",
    name: "old-performer-select",
    redirect: routePaths.performers,
    meta: { requiresAuth: true },
  },

  // Job (Client) Routes
  {
    path: routePaths.requests,
    name: routeNames.requests,
    component: () =>
      import(
        /* webpackChunkName: "webRequests" */ "../views/Requests/Requests.vue"
      ),
    meta: { requiresAuth: true },
  },
  // Review Jobs
  {
    path: routePaths.reviewRequests,
    name: routeNames.reviewRequests,
    component: () =>
      import(
        /* webpackChunkName: "webReviewRequests" */ "../views/Requests/ReviewRequests.vue"
      ),
    meta: { requiresAuth: true },
  },
  // Download Requests
  {
    path: routePaths.downloadRequests,
    name: routeNames.downloadRequests,
    component: () =>
      import(
        /* webpackChunkName: "webDownloadRequests" */ "../views/Requests/DownloadRequests.vue"
      ),
    meta: { requiresAuth: true },
  },

  // Job (Performer) Routes
  {
    path: routePaths.performerPublicUploadVideo,
    name: routeNames.performerPublicUploadVideo,
    component: () =>
      import(
        /* webpackChunkName: "webPerformerPublicUploadVideo" */ "../views/PerformerPublicUploadVideo.vue"
      ),
    meta: {
      customHeader: "NON_AUTH",
    },
  },

  // Authentication
  {
    path: routePaths.login,
    name: routeNames.login,
    component: () =>
      import(/* webpackChunkName: "webLogin" */ "../views/Login/Login.vue"),
    meta: { customHeader: "NO_HEADER", blockAuthenticated: true },
  },
  {
    path: routePaths.register,
    name: routeNames.register,
    component: () =>
      import(
        /* webpackChunkName: "webRegister" */ "../views/Register/Register.vue"
      ),
    meta: {
      customHeader: "NO_HEADER",
    },
  },
  {
    path: routePaths.registerKyc,
    name: routeNames.registerKyc,
    component: () =>
      import(
        /* webpackChunkName: "webRegisterKyc" */ "../views/RegisterKyc.vue"
      ),
    meta: { requiresAuth: true },
  },

  // Quotation (Pricing Calculator)
  {
    path: routePaths.quote,
    name: routeNames.quote,
    component: () =>
      import(
        /* webpackChunkName: "webQuotationQuestionnaire" */ "../views/Quotation/QuotationQuestionnaire.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: routePaths.quoteSummary,
    name: routeNames.quoteSummary,
    component: () =>
      import(
        /* webpackChunkName: "webQuotationSummary" */ "../views/Quotation/QuotationSummary.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: routePaths.quoteInvoice,
    name: routeNames.quoteInvoice,
    component: () =>
      import(
        /* webpackChunkName: "webQuotationInvoice" */ "../views/Quotation/QuotationInvoice.vue"
      ),
  },

  // Order Shipping Details
  {
    path: routePaths.orderShippingDetails,
    name: routeNames.orderShippingDetails,
    component: () =>
      import(
        /* webpackChunkName: "webOrderShippingDetails" */ "../views/Orders/OrderShippingDetails.vue"
      ),
    meta: { requiresAuth: true },
  },

  // Order Details + List
  {
    path: routePaths.orderDetail,
    name: routeNames.orderDetail,
    component: () =>
      import(
        /* webpackChunkName: "webOrderDetail" */ "../views/Orders/OrderDetail.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: routePaths.orders,
    name: routeNames.orders,
    component: () =>
      import(/* webpackChunkName: "webOrders" */ "../views/Orders/Orders.vue"),
    meta: { requiresAuth: true },
  },

  // Creator Content Submit
  {
    path: routePaths.creatorContentSubmit,
    name: routeNames.creatorContentSubmit,
    alias: ["/baka-naman"],
    component: () =>
      import(
        /* webpackChunkName: "webCreatorContentSubmit" */ "../views/CreatorContent/CreatorContentSubmit.vue"
      ),
  },
  // Creator Content Submit (Old, Redirect)
  {
    path: "/creator-content-submit",
    redirect: { name: routeNames.creatorContentSubmit },
  },
  // Creator Content Search
  {
    path: routePaths.creatorContentSearch,
    name: routeNames.creatorContentSearch,
    component: () =>
      import(
        /* webpackChunkName: "webCreatorContentSearch" */ "../views/CreatorContent/CreatorContentSearch.vue"
      ),
    meta: {
      customHeader: "CREATOR_CONTENT",
    },
  },
  // Creator Content Search (Redirect)
  {
    path: "/creator-content/",
    redirect: { name: routeNames.creatorContentSearch },
  },
  // Creator Content Cart
  {
    path: routePaths.creatorContentCart,
    name: routeNames.creatorContentCart,
    component: () =>
      import(
        /* webpackChunkName: "webCreatorContentCart" */ "../views/CreatorContent/CreatorContentCart.vue"
      ),
    meta: {
      customHeader: "CREATOR_CONTENT",
    },
  },
  // Creator Content Summary
  {
    path: routePaths.creatorContentSummary,
    name: routeNames.creatorContentSummary,
    component: () =>
      import(
        /* webpackChunkName: "webCreatorContentSummary" */ "../views/CreatorContent/CreatorContentSummary.vue"
      ),
    meta: {
      customHeader: "CREATOR_CONTENT",
    },
  },
  // Creator Content Thank You
  {
    path: routePaths.creatorContentThankYou,
    name: routeNames.creatorContentThankYou,
    component: () =>
      import(
        /* webpackChunkName: "webCreatorContentThankYou" */ "../views/CreatorContent/CreatorContentThankYou.vue"
      ),
    meta: {
      customHeader: "CREATOR_CONTENT",
    },
  },

  ...sharedRoutes,
];

export const headerRoutes = [];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // @see https://stackoverflow.com/a/57212309 this will ensure user will see top most of the
  // screen when navigating to different pages
  routes: webRoutes,
  scrollBehavior(to, _, savedPosition) {
    return new Promise((resolve, _) => {
      setTimeout(() => {
        // Scrolls back to previous scroll position when going back/forward
        if (savedPosition) {
          resolve(savedPosition);
        }

        /**
         * Scroll to hash if it's set, this is the default browser behavior
         * Else just scroll to the main app location
         */
        resolve({
          el: to.hash || "#app",
          behavior: "smooth",
        });
      }, 25);
    });
  },
});

router.beforeEach(async (to) => {
  // Start the route progress bar.
  NProgress.start();
  const isAuthenticated = store.state.auth.isAuthenticated;

  if (to.meta.requiresAuth && !isAuthenticated) {
    // this route requires auth, check if logged in
    // if not, redirect to login page,
    // and pass the intended url to query params
    return createRedirectedRoute({ name: routeNames.login }, to.fullPath);
  }

  // If going to a page that does not require authentication
  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    // Some pages (like Login page) should not be accessed if user is logged in
    // Check if you can go to the page while authenticated using `blockAuthenticated`
    if (isAuthenticated && to.meta.blockAuthenticated) {
      return { name: routeNames.home };
    }
  }
});

/**
 * Do here fetching of data and redirecting based on certain BE responses
 * See: https://router.vuejs.org/guide/advanced/navigation-guards.html#Global-Resolve-Guards */
router.beforeResolve(async (to) => {
  const isAuthenticated = store.state.auth.isAuthenticated;

  /**
   * Check if BusinessProfile (KYC) still does not have initial KYC page requirements
   * - Nickname
   * - Industry (or Other Industry)
   */
  if (
    isAuthenticated &&
    to.name !== routeNames.registerKyc &&
    to.meta.requiresAuth
  ) {
    const { fetchBusinessProfile } = useBusinessProfile({
      fetchBusinessProfileOnMount: true,
      fetchPolicy: "cache-only",
    });
    const response = await fetchBusinessProfile();
    const parsedResponse =
      parseGqlResponse<BusinessProfile_businessProfile_BusinessProfile>(
        "BusinessProfile",
        response,
        HideIfErrorCode.ALL_ERRORS
      );

    // FIXME: Temporary fix
    // Required to redirect on expired token
    if (
      hasResponseErrorCode(parsedResponse, apiErrorCodes.INVALID_AUTH_TOKEN)
    ) {
      return createRedirectedRoute({ name: routeNames.login }, to.fullPath);
    }

    if (parsedResponse.data && !parsedResponse.error) {
      if (!checkInitialKycRegitrationDone(parsedResponse.data)) {
        return createRedirectedRoute(
          { name: routeNames.registerKyc },
          to.fullPath
        );
      }
    }
  }
});

/** The afterEach hook tells the router that
 *  after a link has completely evaluated to
 *  stop the progress bar, it shouldn’t care
 *  if the page request succeeds */
router.afterEach(async (to) => {
  // Complete the animation of the route progress bar.
  NProgress.done();

  gTagPageViewTrack(to);
  metaPixelTrackPage(to);
  tiktokPixelTrackPage();
});

export default router;
