/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum ContentType {
  PHOTO = "PHOTO",
  VIDEO = "VIDEO",
}

/**
 * An enumeration.
 */
export enum OrderReason {
  ADS = "ADS",
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  NONE = "NONE",
  TIKTOK = "TIKTOK",
}

/**
 * An enumeration.
 */
export enum ProductFeelingChoices {
  BELONGINGNESS = "BELONGINGNESS",
  CALM = "CALM",
  COMFORT = "COMFORT",
  CONFIDENCE = "CONFIDENCE",
  HAPPINESS = "HAPPINESS",
  SUCCESSFUL = "SUCCESSFUL",
}

/**
 * An enumeration.
 */
export enum ProductType {
  DIGITAL = "DIGITAL",
  NONE = "NONE",
  PHYSICAL = "PHYSICAL",
}

/**
 * An enumeration.
 */
export enum ProfileType {
  CLIENT = "CLIENT",
  PERFORMER = "PERFORMER",
}

/**
 * An enumeration.
 */
export enum TargetGenderChoices {
  DOES_NOT_MATTER = "DOES_NOT_MATTER",
  FEMALE = "FEMALE",
  MALE = "MALE",
  NONE = "NONE",
  NON_BINARY = "NON_BINARY",
}

/**
 * An enumeration.
 */
export enum UsersBusinessProfilePendingStepChoices {
  AUDIENCE = "AUDIENCE",
  BASIC = "BASIC",
  DONE = "DONE",
  PRODUCT = "PRODUCT",
  SOCIAL = "SOCIAL",
}

/**
 * An enumeration.
 */
export enum UsersBusinessProfileProductTypeChoices {
  A_ = "A_",
  DIGITAL = "DIGITAL",
  PHYSICAL = "PHYSICAL",
}

/**
 * An enumeration.
 */
export enum UsersBusinessProfileTargetGenderChoices {
  A_ = "A_",
  DOES_NOT_MATTER = "DOES_NOT_MATTER",
  FEMALE = "FEMALE",
  MALE = "MALE",
  NON_BINARY = "NON_BINARY",
}

/**
 * An enumeration.
 */
export enum UsersPerformerProfileGenderChoices {
  FEMALE = "FEMALE",
  MALE = "MALE",
  NON_BINARY = "NON_BINARY",
}

/**
 * An enumeration.
 */
export enum VideoIdeasVideoIdeaDurationChoices {
  LENGTH_12_TO_20_SECS = "LENGTH_12_TO_20_SECS",
  LENGTH_20_TO_30_SECS = "LENGTH_20_TO_30_SECS",
  LENGTH_31_TO_45_SECS = "LENGTH_31_TO_45_SECS",
  LENGTH_5_TO_8_SECS = "LENGTH_5_TO_8_SECS",
}

export interface AddPerformerToOrderInput {
  orderId?: string | null;
  userId?: string | null;
}

export interface AddToCartInput {
  videoIdeaId: string;
  script?: string | null;
}

export interface AuthenticateViaEmailInput {
  email: string;
  password: string;
  fullName?: string | null;
  industryOther?: string | null;
  industryId?: number | null;
  profileType?: ProfileType | null;
  isRegistration?: boolean | null;
}

export interface AuthenticateViaGoogleInput {
  authorizationCode: string;
  redirectUri?: string | null;
  nickname?: string | null;
  industryOther?: string | null;
  industryId?: number | null;
  profileType?: ProfileType | null;
}

export interface BasicInformationInput {
  nickname: string;
  productType?: ProductType | null;
  industryOther?: string | null;
  industryId?: number | null;
  phoneNumber?: string | null;
}

export interface CheckOutOrderInput {
  notes?: string | null;
}

export interface CheckPromoCodeInput {
  code: string;
}

export interface CheckoutCreatorContentInput {
  ids: (string | null)[];
  name: string;
  email?: string | null;
  phone?: string | null;
  socialMediaLink?: string | null;
}

export interface ConfigInput {
  name: string;
}

export interface ConfirmPerformersInOrderInput {
  orderId?: string | null;
}

export interface CreateQuotationInput {
  reuseQuoteFromLatestOrder?: boolean | null;
  noAddons?: boolean | null;
}

export interface CreatorContentSubmissionsInput {
  ids?: (string | null)[] | null;
  query?: string | null;
  creatorQuery?: string | null;
  pagination?: PaginationInput | null;
}

export interface DownloadOrderVideosInput {
  orderId: string;
}

export interface DownloadRequestVideosInput {
  jobId: string;
}

export interface GenerateShortenedLinkInput {
  url: string;
  preferredShortUrl?: string | null;
}

export interface IdInput {
  id: string;
}

export interface JobCommentInputType {
  jobId: string;
  comment: string;
}

export interface JobFilterInput {
  orderId?: number | null;
  statusKeys?: string[] | null;
  pagination?: PaginationInput | null;
}

export interface OrderShippingDetailsInput {
  orderId?: string | null;
}

export interface OrdersFilterInput {
  status?: string | null;
  videoIdeaId?: string | null;
  pagination?: PaginationInput | null;
}

export interface PaginationInput {
  after?: string | null;
  pageSize: number;
}

export interface PerformersInput {
  pagination: PaginationInput;
  query?: string | null;
  industryId?: number | null;
  minAge?: number | null;
  maxAge?: number | null;
  gender?: string | null;
  isPublic?: boolean | null;
}

export interface ProductInformationInput {
  productId?: string | null;
  productDescription?: string | null;
  productAdjectives?: (string | null)[] | null;
  productFeelings?: (ProductFeelingChoices | null)[] | null;
}

export interface ProductsInput {
  industryId?: string | null;
}

export interface QuotationInput {
  quotationId?: string | null;
  quotationUuid?: string | null;
}

export interface RecentlyUsedVideoIdeasInput {
  pagination: PaginationInput;
}

export interface RecommendedPerformersInput {
  orderId?: number | null;
  includePremium?: boolean | null;
}

export interface RelatedVideoIdeasInput {
  id: string;
  pagination: PaginationInput;
}

export interface RemoveFromCartInput {
  cartItemId?: string | null;
}

export interface RemoveOrderShippingDetailInput {
  shippingDetailId?: string | null;
  orderId?: string | null;
}

export interface RemovePerformerFromOrderInput {
  orderId?: string | null;
  userId?: string | null;
}

export interface ReviewJobsInput {
  orderId: string;
  approvedJobIds?: (string | null)[] | null;
  comments?: (JobCommentInputType | null)[] | null;
}

export interface SaveOrderShippingDetailInput {
  shippingDetailId?: string | null;
  orderId?: string | null;
  performerId?: string | null;
  courier?: string | null;
  trackingNumberOrLink?: string | null;
  notes?: string | null;
}

export interface SendJobConversationMessageInput {
  jobId: string;
  content: string;
}

export interface ShortenedLinkInput {
  url: string;
}

export interface SocialMediaInput {
  companyWebsiteUrl?: string | null;
  facebookUrl?: string | null;
  instagramUrl?: string | null;
  tiktokUrl?: string | null;
  orderReasons?: (OrderReason | null)[] | null;
  orderReasonOther?: string | null;
}

export interface SubmitCreatorContentInput {
  contentType?: ContentType | null;
  brand: string;
  brandInstagramUrl?: string | null;
  creatorName: string;
  creatorInstagramUrl?: string | null;
  phoneNumber?: string | null;
  link?: string | null;
  email: string;
  photoPrice?: any | null;
  videoPrice?: any | null;
}

export interface SubmitPublicCreatorRequestInput {
  performerId: string;
  businessName: string;
  phoneNumber?: string | null;
  email: string;
  instagramUrl?: string | null;
  description?: string | null;
  referrer?: string | null;
  videoCount: number;
  discovery?: string | null;
}

export interface SubscribeToNewsletterInput {
  email: string;
}

export interface SubscribeToPlanInput {
  subscriptionPlanId: string;
}

export interface TargetAudienceInput {
  targetGender: TargetGenderChoices;
  targetMinAge: number;
  targetMaxAge: number;
  targetAudienceDescription: string;
}

export interface UpdateBusinessProfileInput {
  basicInformation?: BasicInformationInput | null;
  socialMedia?: SocialMediaInput | null;
  productInformation?: ProductInformationInput | null;
  targetAudience?: TargetAudienceInput | null;
}

export interface UpdateCartItemInput {
  cartItemId?: string | null;
  text?: string | null;
}

export interface UpdateCartNotesInput {
  notes?: string | null;
}

export interface UpdateQuotationInput {
  quotationId: string;
  hireStrategist?: boolean | null;
  useTestimonialPackage?: boolean | null;
  useExtendedVideoLength?: boolean | null;
  choosePerformers?: boolean | null;
  performersPreference?: string | null;
  usePremiumPerformers?: boolean | null;
  useExpress?: boolean | null;
  usePerformerTravel?: boolean | null;
  sendProducts?: boolean | null;
  useSameDayDelivery?: boolean | null;
  returnProducts?: boolean | null;
  revisionRequests?: boolean | null;
  subscriptionPlanId?: string | null;
  promoCode?: string | null;
}

export interface UploadJobVideosInput {
  jobId: string;
  name?: string | null;
}

export interface VideoIdeasInput {
  query?: string | null;
  purposeId?: string | null;
  audienceId?: string | null;
  typeId?: string | null;
  industryId?: string | null;
  pagination: PaginationInput;
  trendy?: boolean | null;
  isPublic?: boolean | null;
  duration?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
