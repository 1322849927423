
import routeNames from "@/web/router/routeNames";
import { defineComponent } from "vue";
import BrandIcon from "@/shared/components/Icons/BrandIcon.vue";
import { useI18n } from "vue-i18n";
import { useWindowSizeChecker } from "@/shared/utils/windowSizeChecker";
import { useCreatorContentCart } from "@/shared/composables/useCreatorContentCart";
import { ShoppingCartOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const { isMobile } = useWindowSizeChecker();

    const { cartItemCount } = useCreatorContentCart({
      disableCartListQuery: true,
    });

    return {
      t,
      routeNames,
      isMobile,
      cartItemCount,
    };
  },
  components: { BrandIcon, ShoppingCartOutlined },
});
