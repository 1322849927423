import JSZip from "jszip";
import { computed, ref } from "vue";
import {
  DownloadLink,
  triggerDownloadViaAnchor,
} from "@/shared/utils/downloadUtils";
import axios, { AxiosResponse } from "axios";

type DownloadProgress = {
  current: number;
  total: number;
  percent: number;
  filename?: string;
};
export type DownloadFilesOptions = {
  files: DownloadLink[];
  zipFileName?: string;
};

const DELAY_BETWEEN_DOWNLOADS = 1500;

/** Used to create delay */
function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const useFileDownloader = () => {
  const controller = ref(new AbortController());
  const isDownloading = ref(false);
  const isDone = ref(false);
  const hasError = ref(false);
  const currentDownloadFilename = ref("");

  /** [Current, Total] of all items */
  const downloadProgressData = ref<Array<DownloadProgress>>([]);
  /** Aggregated [Current, Total] */
  const totalProgressData = computed<DownloadProgress>(() => {
    return downloadProgressData.value.reduce(
      (a, b) => {
        const current = a.current + b.current;
        const total = a.total + b.total;

        const percent = Math.round((a.percent + b.percent) / 2); // solve NaN

        return { current, total, percent };
      },
      { current: 0, total: 0, percent: 0 }
    );
  });

  const resetState = (files: DownloadLink[]) => {
    controller.value = new AbortController();
    isDownloading.value = false;
    isDone.value = false;
    hasError.value = false;

    // Init Download Progress Array
    downloadProgressData.value = [];
    files.forEach((file) => {
      downloadProgressData.value.push({
        current: 0,
        percent: 0,
        total: 0,
        filename: file.filename,
      });
    });
  };

  const updateProgress = (index: number, current: number, total: number) => {
    if (index > downloadProgressData.value.length) {
      return;
    }

    downloadProgressData.value[index] = {
      // Preserve filename
      ...downloadProgressData.value[index],
      // Update values
      current,
      total,
      percent: Math.round((current / total) * 100),
    };
  };

  const downloadFiles = async (options: DownloadFilesOptions) => {
    const files = options.files;
    const zipFileName = options.zipFileName || "download.zip";
    currentDownloadFilename.value = zipFileName;

    // Logging for LogRocket
    console.log(files);

    resetState(files);

    isDownloading.value = true;

    const promisesList: Promise<AxiosResponse>[] = [];

    // Add delay between initiating downloads to prevent possible throttling from external servers
    for (let index = 0; index < files.length; index++) {
      await delay(DELAY_BETWEEN_DOWNLOADS);

      promisesList.push(
        axios.get(files[index].link, {
          responseType: "blob",
          onDownloadProgress: (event: ProgressEvent) => {
            if (event.lengthComputable) {
              updateProgress(index, event.loaded, event.total);
            }
          },
          signal: controller.value.signal,
        })
      );
    }

    const promises = await Promise.allSettled(promisesList);

    const zip = new JSZip();

    // Loop over, add to ZIP
    for (let i = 0; i < promises.length; i++) {
      const promise = promises[i];

      if (promise.status === "fulfilled") {
        // Ex. video/mp4
        const contentType = promise.value.headers["content-type"] ?? "";
        const fileExtension = contentType.split("/").at(1) ?? "";
        // Get filename from method caller, or generate one from index and response
        const filename =
          files.at(i)?.filename ??
          `${String(i).padStart(4, "0")}.${fileExtension}`;

        // Add to ZIP file
        try {
          zip.file(filename, await promise.value.data, {
            base64: true,
          });
        } catch (e) {
          console.error("error in adding file to zip: ", filename, e);
        }
      } else {
        console.error("error in downloading file: ", promise.reason);
      }
    }

    // Download ZIP
    try {
      const zipData = await zip.generateAsync({ type: "blob" });
      triggerDownloadViaAnchor({ data: zipData, filename: zipFileName });
    } catch (error) {
      console.error("error in zipping: ", error);
      console.groupEnd();
    }

    // Update refs
    isDone.value = true;
    isDownloading.value = false;
    hasError.value = promises.some(
      (download) => download.status === "rejected"
    );
  };

  const cancelDownload = () => {
    controller.value.abort();
    isDownloading.value = false;
  };

  return {
    isDone,
    hasError,
    downloadProgressData,
    totalProgressData,
    downloadFiles,
    isDownloading,
    cancelDownload,
    currentDownloadFilename,
  };
};
