
import { defineComponent, onMounted } from "vue";
import LayoutFooter from "@/shared/components/Layouts/LayoutFooter.vue";
import LayoutAuthenticatedHeader from "@/shared/components/Layouts/LayoutAuthenticatedHeader.vue";
import LayoutNonAuthenticatedHeader from "@/shared/components/Layouts/LayoutNonAuthenticatedHeader.vue";
import LayoutCreatorContentHeader from "@/shared/components/Layouts/LayoutCreatorContentHeader.vue";
import { loadHubspotChatWidget } from "@/shared/utils/hubspot";
import { HeaderTypes } from "@/web/router";

export default defineComponent({
  setup() {
    onMounted(() => {
      loadHubspotChatWidget();
    });

    return { HeaderTypes };
  },
  components: {
    LayoutAuthenticatedHeader,
    LayoutFooter,
    LayoutNonAuthenticatedHeader,
    LayoutCreatorContentHeader,
  },
});
