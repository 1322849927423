
import { i18nTranslate } from "@/plugins/i18n";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import routeNames from "@/shared/router/routeNames";
import { openInNewTab } from "@/shared/utils/browser";
import { config, socialLinks } from "@/shared/utils/config";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import NewsletterForm from "../NewsletterForm.vue";
import PageLayout from "./PageLayout.vue";

// Footer links placed here instead of config.ts because plugins haven't been initialized
export const footerLinks = [
  {
    label: i18nTranslate("Home"),
    url: config.merchantInfoHomeUrl,
  },
  {
    label: i18nTranslate("About Us"),
    url: config.merchantInfoAboutUsUrl,
  },
  {
    label: i18nTranslate("Plans"),
    url: config.merchantInfoPlansUrl,
  },
  {
    label: i18nTranslate("Blog"),
    url: config.merchantInfoBlogUrl,
  },
  {
    label: i18nTranslate("Careers"),
    url: config.merchantInfoCareersUrl,
  },
  {
    label: i18nTranslate("Terms of Use"),
    url: config.termsWebsiteUseUrl,
  },
];

export default defineComponent({
  setup() {
    const { t } = useI18n();

    return {
      t,
      routeNames,
      socialLinks,
      footerLinks,
      config,
    };
  },
  components: {
    AppIcon,
    NewsletterForm,
    PageLayout,
  },
  methods: {
    openInNewTab,
  },
});
