import InfoCircleOutlined from "@ant-design/icons-vue/InfoCircleOutlined";
import CheckCircleOutlined from "@ant-design/icons-vue/CheckCircleOutlined";
import CloseCircleOutlined from "@ant-design/icons-vue/CloseCircleOutlined";
import ExclamationCircleOutlined from "@ant-design/icons-vue/ExclamationCircleOutlined";

export enum CommonAlertTypes {
  info = "info",
  success = "success",
  warning = "warning",
  error = "error",
}

export const commonAlertIconsMap = {
  [CommonAlertTypes["info"]]: InfoCircleOutlined,
  [CommonAlertTypes["success"]]: CheckCircleOutlined,
  [CommonAlertTypes["error"]]: CloseCircleOutlined,
  [CommonAlertTypes["warning"]]: ExclamationCircleOutlined,
};
