import { CheckoutCreatorContentInput } from "@/../__generated__/globalTypes";
import {
  CreatorContentSubmissions,
  CreatorContentSubmissionsVariables,
  CreatorContentSubmissions_creatorContentSubmissions_CreatorContentSubmissionResults,
} from "@/api/creatorContent/__generated__/CreatorContentSubmissions";
import {
  CreatorContentSubmissionsTotalPrice,
  CreatorContentSubmissionsTotalPriceVariables,
  CreatorContentSubmissionsTotalPrice_creatorContentSubmissions_CreatorContentSubmissionResults,
} from "@/api/creatorContent/__generated__/CreatorContentSubmissionsTotalPrice";
import {
  creatorContentSubmissionsGql,
  creatorContentSubmissionsTotalPriceGql,
} from "@/api/creatorContent/creatorContentSubmissions";
import { useCheckoutCreatorContent } from "@/shared/composables/useCreatorContent";
import { createLocalStorageStore } from "@/shared/utils/createLocalStorageStore";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { LocalStorageKeys } from "@/shared/utils/localStorage";
import { useLazyQuery, useQuery } from "@vue/apollo-composable";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { config } from "@/shared/utils/config";

// STATE
const { store: creatorContentCartStore, resetState } = createLocalStorageStore({
  defaultState: { ids: [] as string[] },
  key: LocalStorageKeys.CREATOR_CONTENT_CART,
  deep: true,
});
// Computed State
const isCartEmpty = computed(() => !creatorContentCartStore.value.ids.length);

type FormState = Omit<CheckoutCreatorContentInput, "ids">;

/**
 * Provides utility functions and variables for local storage saved cart items
 * for Creator Content
 */
export const useCreatorContentCart = (options?: {
  /** Force disable cart list query when only totalPrice is needed */
  disableCartListQuery?: boolean;
}) => {
  const { n } = useI18n();
  // const router = useRouter();
  /** Keeps track if already checked out. */
  const checkedOutItems = ref<string[]>([]);

  // === Queries and Mutations ===
  /** For Cart List */
  const cartListUpdating = ref(false);
  const {
    result: cartListResult,
    loading: cartListResultLoading,
    refetch: cartListRefetch,
    load: cartListLoad,
  } = useLazyQuery<
    CreatorContentSubmissions,
    CreatorContentSubmissionsVariables
  >(
    creatorContentSubmissionsGql,
    { input: { ids: [] } },
    { fetchPolicy: "cache-and-network" }
  );

  const cartListParsedResponse = computed(() => {
    return parseGqlResponse<CreatorContentSubmissions_creatorContentSubmissions_CreatorContentSubmissionResults>(
      "CreatorContentSubmissionResults",
      cartListResult.value
    ).data;
  });

  /** For Total Price Only (footer) */
  const { result: cartPriceResult, loading: cartPriceLoading } = useQuery<
    CreatorContentSubmissionsTotalPrice,
    CreatorContentSubmissionsTotalPriceVariables
  >(
    creatorContentSubmissionsTotalPriceGql,
    () => ({ input: { ids: creatorContentCartStore.value.ids } }),
    () => ({ enabled: !isCartEmpty.value })
  );

  const {
    checkoutCreatorContent,
    checkoutCreatorContentLoading: checkoutCartLoading,
  } = useCheckoutCreatorContent();

  // === Computed Properties ===
  const cartList = computed(() => {
    if (isCartEmpty.value) {
      return [];
    }

    return cartListParsedResponse.value?.results;
  });

  const cartListLoading = computed(
    () => cartListResultLoading.value && !cartListUpdating.value
  );

  const cartPrice = computed(() => {
    if (isCartEmpty.value) {
      return 0;
    }

    const parsedResponse =
      parseGqlResponse<CreatorContentSubmissionsTotalPrice_creatorContentSubmissions_CreatorContentSubmissionResults>(
        "CreatorContentSubmissionResults",
        cartPriceResult.value
      );

    return Number(parsedResponse.data?.totalPrice ?? 0);
  });

  const cartItemCount = computed(
    () => creatorContentCartStore.value.ids.length
  );

  /** Total Price of Items */
  const formattedCartPrice = computed(() =>
    n(cartPrice.value, "currency", "ph")
  );
  /** Discounted Merchant's Fee */
  const merchantBrandFee = computed(
    () => cartPrice.value * config.creatorContentFeePercentage
  );
  /** Grand Total */
  const grandTotal = computed(() => merchantBrandFee.value + cartPrice.value);
  /** Base Merchant's Fee */
  const originalBrandFee = computed(() => cartPrice.value * 0.5);

  // === Handlers ===
  const addToCart = (id: string) => {
    creatorContentCartStore.value.ids.push(id);
  };

  const removeFromCart = (id: string) => {
    const index = creatorContentCartStore.value.ids.findIndex(
      (item) => item == id
    );
    if (index >= 0) {
      creatorContentCartStore.value.ids.splice(index, 1);
    }
  };

  const clearCart = () => {
    resetState({ ids: [] });
  };

  /** @returns If success or not. */
  const checkoutCart = async (formState: FormState): Promise<boolean> => {
    const response = await checkoutCreatorContent({
      ids: creatorContentCartStore.value.ids,
      ...formState,
    });

    const success = !!response?.success;

    if (success) {
      checkedOutItems.value = creatorContentCartStore.value.ids;
      clearCart();
    }

    return success;
  };

  /** Fetch cart query manually */
  const fetchCart = async () => {
    const ids = creatorContentCartStore.value.ids;

    cartListLoad();
    // Only fetch if allowed and there are items
    if (
      !options?.disableCartListQuery &&
      !checkedOutItems.value.length &&
      !!ids.length
    ) {
      await cartListRefetch({
        input: { ids: creatorContentCartStore.value.ids },
      });
    }
  };
  /** Watch for updates */
  watch(
    creatorContentCartStore,
    async () => {
      cartListUpdating.value = true;

      try {
        await fetchCart();
      } catch (e) {
        console.error("Failed to refetch cart list: ", e);
      }

      cartListUpdating.value = false;
    },
    { immediate: true, deep: true }
  );
  /** Fetch on load */
  onMounted(async () => {
    if (!options?.disableCartListQuery) {
      await fetchCart();
    }
  });

  return {
    creatorContentCartStore,
    cartItemCount,
    addToCart,
    removeFromCart,
    clearCart,
    isCartEmpty,
    cartList,
    cartListLoading,
    cartListUpdating,
    checkoutCart,
    checkoutCartLoading,

    cartPrice,
    cartPriceLoading,
    formattedCartPrice,
    merchantBrandFee,
    originalBrandFee,
    grandTotal,
  };
};
