import { createStore } from "vuex";
import { authStore, AuthState } from "./authStore";
import { vuexLocal } from "./vuexLocal";
import { logRocketVuexPlugin } from "@/shared/utils/logrocket";

export type RootState = {
  auth: AuthState;
};

export default createStore<RootState>({
  modules: {
    auth: authStore,
  },
  plugins: [vuexLocal.plugin, ...logRocketVuexPlugin],
});
