import { computed, reactive } from "vue";

const modalVisibilityMap = reactive({
  HOW_TO_ORDER: false,
  ASK_FOR_QUOTATION: false,
  LEARN_MORE: false,
  REUSE_QUOTATION: false,
  FILE_DOWNLOADER: false,
});

export type GlobalModalKeys = keyof typeof modalVisibilityMap;

/**
 * A composable to help control modals that can be toggled in Global scope.
 * - Add a unique key identifier above, in `GlobalModalKeys` and `modalVisibilityMap`
 * - Place modal components in `WebApp` component.
 *
 * Note: Can be used to open single instance of each modal simultaneously,
 * but not of the same component type.
 *
 * @example
 *
 * ### In component that will control the modal visibility
 * ```ts
 * const { openGlobalModal, closeGlobalModal } = useGlobalModal("YOUR_MODAL_KEY");
 *
 * // Methods
 * openGlobalModal();
 * closeGlobalModal();
 * ```
 *
 * ### In `WebApp.vue`
 * ```html
 * <Teleport to="body">
 *  <AGlobalModal />
 * </Teleport>
 * ```
 *
 * ### In `AGlobalModal.vue` (your custom modal)
 * ```html
 * <!--
 * Bind to this value using the `useGlobalModal` composable,
 * providing the same key above `YOUR_MODAL_KEY`.
 * -->
 * <a-modal :visible="isGlobalModalVisible">
 *  ...
 * </a-modal>
 * ```
 */
export const useGlobalModal = (key: GlobalModalKeys) => {
  const openGlobalModal = () => {
    modalVisibilityMap[key] = true;
  };

  const closeGlobalModal = () => {
    modalVisibilityMap[key] = false;
  };

  const isGlobalModalVisible = computed(() => modalVisibilityMap[key]);

  return {
    isGlobalModalVisible,
    openGlobalModal,
    closeGlobalModal,
  };
};
