import defaultTo from "lodash/defaultTo";
import toNumber from "lodash/toNumber";

const recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY || "";
const logRocketId = process.env.VUE_APP_LOG_ROCKET_ID || "";

export const config = {
  allowAutomatedTestMode:
    process.env.VUE_APP_ALLOW_AUTOMATED_TEST_MODE === "true",
  httpGqlServerUrl: process.env.VUE_APP_HTTP_GQL_SERVER_URL || "",
  websocketGqlServerUrl: process.env.VUE_APP_WEBSOCKET_GQL_SERVER_URL || "",
  merchantBrand: process.env.VUE_APP_MERCHANT_BRAND || "",
  merchantBrandShortName:
    process.env.VUE_APP_MERCHANT_BRAND_SHORT_NAME ||
    process.env.VUE_APP_MERCHANT_BRAND ||
    "",
  contactEmail: process.env.VUE_APP_MERCHANT_BRAND_CONTACT_EMAIL || "",
  copyrightYear: process.env.VUE_APP_COPYRIGHT_YEAR,

  // GTAG
  gtagEnabled:
    process.env.VUE_APP_G_TAG_ENABLED === "true" &&
    !!process.env.VUE_APP_G_TAG_ID,
  gtagId: process.env.VUE_APP_G_TAG_ID || "",

  /**
   * LOCALIZATION AND NUMBER FORMATS
   * for number format convert to number, .env returns string
   */
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  minimumFractionDigits: toNumber(
    defaultTo(process.env.VUE_APP_NUMBER_FORMAT_MINIMUM_FRACTION, 0)
  ),
  maximumFractionDigits: toNumber(
    process.env.VUE_APP_NUMBER_FORMAT_MAXIMUM_FRACTION || 2
  ),

  /**
   * Authenticator Download URL
   */
  authenticatorAppStoreURL: process.env.VUE_APP_APPSTORE_URL || "",
  authenticatorGooglePlayURL: process.env.VUE_APP_GOOGLEPLAY_URL || "",

  /**
   * reCAPTCHA
   */
  recaptchaSiteKey,
  isCaptchaEnabled: !!recaptchaSiteKey,

  /**
   * Logrocket
   *
   * have to check against string "true" because .env uses string type
   * only allow logrocket on development/test environment
   */
  logRocketId,
  logRocketEnabled:
    process.env.VUE_APP_LOG_ROCKET_ENABLE === "true" && !!logRocketId,

  /**
   * Microsoft Clarity
   */
  microsoftClarityId: process.env.VUE_APP_MICROSOFT_CLARITY_ID || "",

  /**
   * Facebook Meta Pixel
   */
  facebookMetaPixelId: process.env.VUE_APP_FB_META_PIXEL || "",

  /**
   * Tiktok Pixel
   */
  tiktokPixelId: process.env.VUE_APP_TIKTOK_PIXEL_ID || "",

  /** static links */
  contactUrl: process.env.VUE_APP_CONTACT_URL,
  privacyPolicyUrl: process.env.VUE_APP_PRIVACY_POLICY_URL,
  cookiePolicyUrl: process.env.VUE_APP_COOKIE_POLICY_URL,
  termsWebsiteUseUrl: process.env.VUE_APP_TERMS_WEBSITE_USE_URL,
  learnMoreDomainUrl: process.env.VUE_APP_LEARN_MORE_DOMAIN_URL || "#",

  // Social links
  merchantFacebookUrl: process.env.VUE_APP_MERCHANT_BRAND_FACEBOOK_URL,
  merchantTwitterUrl: process.env.VUE_APP_MERCHANT_BRAND_TWITTER_URL,
  merchantInstagramUrl: process.env.VUE_APP_MERCHANT_BRAND_INSTAGRAM_URL,
  merchantLinkedinUrl: process.env.VUE_APP_MERCHANT_BRAND_LINKEDIN_URL,
  merchantTiktokUrl: process.env.VUE_APP_MERCHANT_BRAND_TIKTOK_URL,
  merchantEmailUrl: process.env.VUE_APP_MERCHANT_BRAND_EMAIL_URL,
  merchantSupportEmailUrl: process.env.VUE_APP_MERCHANT_BRAND_SUPPORT_EMAIL_URL,

  // InfoSite links
  merchantInfoHomeUrl: process.env.VUE_APP_MERCHANT_INFO_HOME_URL,
  merchantInfoAboutUsUrl: process.env.VUE_APP_MERCHANT_INFO_ABOUT_US_URL,
  merchantInfoPlansUrl: process.env.VUE_APP_MERCHANT_INFO_PLANS_URL,
  merchantInfoBlogUrl: process.env.VUE_APP_MERCHANT_INFO_BLOG_URL,
  merchantInfoCareersUrl: process.env.VUE_APP_MERCHANT_INFO_CAREERS_URL,

  /**
   * social/3rd party authentication
   */
  authBaseUrl: process.env.VUE_APP_AUTH_BASE_URL,
  authGoogleClientId: process.env.VUE_APP_AUTH_GOOGLE_CLIENT_ID,
  authGoogleRedirectUri: process.env.VUE_APP_AUTH_GOOGLE_REDIRECT_URI,

  /**
   * Other URLs
   */
  cartPlaceholderInspirationUrl: process.env.VUE_APP_CART_INSPIRATION_URL,

  customVideoIdeaId: process.env.VUE_APP_CUSTOM_VIDEO_IDEA_TEMPLATE_ID,

  /** Creator Content Fee: Percentage from total */
  creatorContentFeePercentage: Number(
    process.env.VUE_APP_CREATOR_CONTENT_FEE_PERCENTAGE ?? 0.3
  ),

  /** HUBSPOT CHAT */
  isHubspotChatWidgetEnabled: process.env.VUE_APP_HUBSPOT_ENABLE === "true",
};

export const socialLinks = [
  {
    label: "Facebook",
    appIconName: "facebook",
    url: config.merchantFacebookUrl,
  },
  {
    label: "Twitter",
    appIconName: "twitter",
    url: config.merchantTwitterUrl,
  },
  {
    label: "Instagram",
    appIconName: "instagram",
    url: config.merchantInstagramUrl,
  },
  {
    label: "Linkedin",
    appIconName: "linkedin",
    url: config.merchantLinkedinUrl,
  },
  {
    label: "Tiktok",
    appIconName: "tiktok",
    url: config.merchantTiktokUrl,
  },
  {
    label: "Email",
    appIconName: "email",
    url: config.merchantEmailUrl,
  },
];

// Update once we need to export individual widgets/component
export const isFullSite = true;
