import {
  DownloadFilesOptions,
  useFileDownloader,
} from "@/shared/composables/useFileDownloader";
import { useGlobalModal } from "./useGlobalModal";
import { computed, ref } from "vue";

/** Declared here to have shared state on app. */
const {
  totalProgressData,
  downloadFiles: _downloadFiles,
  cancelDownload,
  downloadProgressData,
  hasError,
  isDone,
  isDownloading,
  currentDownloadFilename,
} = useFileDownloader();
const showIndividualProgressBars = ref(false);

export const useFileDownloaderModal = () => {
  const { openGlobalModal, closeGlobalModal, isGlobalModalVisible } =
    useGlobalModal("FILE_DOWNLOADER");

  /** Wrapper method to include modal options.  */
  const downloadFiles = async (
    opts: DownloadFilesOptions & {
      showIndividualProgressBars?: boolean;
    }
  ) => {
    showIndividualProgressBars.value = opts.showIndividualProgressBars ?? false;
    console.log(opts.showIndividualProgressBars);

    openGlobalModal();

    await _downloadFiles(opts);
  };

  const isPopUpVisible = computed(
    () => !isGlobalModalVisible.value && isDownloading.value
  );

  return {
    // Downloader
    totalProgressData,
    downloadFiles,
    cancelDownload,
    downloadProgressData,
    hasError,
    isDone,
    isDownloading,

    // Modal
    openGlobalModal,
    closeGlobalModal,
    isGlobalModalVisible,
    isPopUpVisible,
    showIndividualProgressBars,
    currentDownloadFilename,
  };
};
