import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "file-downloader-modal__popup z-50 fixed bottom-4 right-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_progress = _resolveComponent("a-progress")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_DownloadOutlined = _resolveComponent("DownloadOutlined")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_modal, {
      visible: _ctx.isGlobalModalVisible,
      title: _ctx.t('Downloading...'),
      onCancel: _ctx.closeGlobalModal,
      centered: ""
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_a_button, {
          type: "secondary",
          onClick: _ctx.cancelDownload,
          disabled: !_ctx.isDownloading,
          size: "small"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Cancel")), 1)
          ]),
          _: 1
        }, 8, ["onClick", "disabled"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          (_ctx.showIndividualProgressBars)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.downloadProgressData, (progress, index) => {
                return (_openBlock(), _createElementBlock("div", { key: index }, [
                  _createVNode(_component_a_progress, {
                    percent: progress.percent,
                    status: _ctx.progressBarStatus
                  }, null, 8, ["percent", "status"]),
                  _createVNode(_component_a_typography_text, { class: "text-sm" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(progress.filename), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]))
              }), 128))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_a_progress, {
                  percent: _ctx.totalProgressData.percent,
                  status: _ctx.progressBarStatus
                }, null, 8, ["percent", "status"]),
                _createVNode(_component_a_typography_text, { class: "text-sm" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.currentDownloadFilename), 1)
                  ]),
                  _: 1
                })
              ], 64))
        ])
      ]),
      _: 1
    }, 8, ["visible", "title", "onCancel"]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_1, [
          _createVNode(_component_a_button, {
            type: "primary",
            shape: "circle",
            onClick: _ctx.openGlobalModal,
            class: "w-14 h-14"
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_DownloadOutlined, { class: "text-2xl" })
            ]),
            _: 1
          }, 8, ["onClick"])
        ], 512), [
          [_vShow, _ctx.isPopUpVisible]
        ])
      ]),
      _: 1
    })
  ], 64))
}