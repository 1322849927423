import { getCurrentInstance, ref } from "vue";

export function useSocialAuthProvider() {
  /**
   * Vue 3 provides getCurrentInstance() inside the setup() hook. That instance allows access to global properties
   * (installed from plugins) via appContext.config.globalProperties
   *
   * https://stackoverflow.com/questions/66957578/how-to-get-the-this-instance-in-vue-3
   */
  const instance = getCurrentInstance();
  const auth = instance?.appContext?.config?.globalProperties.$auth;

  /**
   * this saves the current provider being authenticated
   * to determine what provider is loading
   */
  const authenticatingProvider = ref("");

  /**
   * TODO: create provider enum if there is more than one social provider
   */
  const authenticate = async (provider = "google") => {
    if (!auth) {
      console.error("VueAuthenticate instance not found!");
    }

    try {
      // set the current provider being authenticated
      authenticatingProvider.value = provider;

      /**
       * try to link the user to the provider
       * this will not try to authenticated the user with the authorization code
       * but will just get the authorization code and save it to the backend
       */
      const response = await auth.link(provider);

      console.log("response", response);

      return { provider, ...response };
    } catch (error) {
      console.log("Failed to authenticate", error);
    } finally {
      authenticatingProvider.value = "";
    }
  };

  return {
    authenticate,
    authenticatingProvider,
  };
}
