import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex-grow mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_FormGroup = _resolveComponent("FormGroup")!

  return (_openBlock(), _createBlock(_component_FormGroup, {
    model: _ctx.formState,
    layout: "inline",
    class: "newsletter-form",
    onFinish: _ctx.handleSubmit,
    onFinishFailed: _ctx.handleFailed,
    loading: _ctx.subscribeToNewsletterLoading
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_InputText, {
          name: "email",
          placeholder: _ctx.t('Email address'),
          value: _ctx.formState.email,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.email) = $event)),
          rules: [
          _ctx.makeRequiredRule(_ctx.t('This field is required.')),
          _ctx.makeEmailRule(_ctx.t('Please enter a valid email address.')),
        ]
        }, null, 8, ["placeholder", "value", "rules"])
      ]),
      _createVNode(_component_a_button, {
        type: "primary",
        class: "newsletter-form__submit-btn lighter",
        "html-type": "submit",
        loading: _ctx.subscribeToNewsletterLoading
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("Sign Up")), 1)
        ]),
        _: 1
      }, 8, ["loading"])
    ]),
    _: 1
  }, 8, ["model", "onFinish", "onFinishFailed", "loading"]))
}