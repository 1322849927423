
import { defineComponent, PropType } from "vue";
import { TypeStepBarOptions } from "@/shared/composables/useStepBar";

export default defineComponent({
  props: {
    stepBarOptions: {
      type: Object as PropType<TypeStepBarOptions>,
      required: true,
    },
  },
  setup() {
    return {};
  },
  methods: {},
  components: {},
});
