
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { TypeStepBarOptions } from "@/shared/composables/useStepBar";
import LeftOutlined from "@ant-design/icons-vue/LeftOutlined";
import StepBar from "@/shared/components/StepBar.vue";

export default defineComponent({
  emits: ["on-back"],
  props: {
    fullWidth: Boolean,
    title: String,
    subtitle: String,
    backButton: Boolean,
    narrow: Boolean,
    narrower: Boolean,
    narrowest: Boolean,
    stepBarOptions: Object as PropType<TypeStepBarOptions>,
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
  methods: {},
  components: {
    LeftOutlined,
    StepBar,
  },
});
