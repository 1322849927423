import sharedRouteNames from "@/shared/router/routeNames";

export default {
  login: "login",
  register: "register",
  registerKyc: "register-kyc",
  // Dashboard
  profile: "profile",
  plans: "plans",
  cart: "cart",
  kyc: "kyc",
  thankYou: "thankYou",

  // Video Routes
  videoDetails: "video-details",
  publicVideoGallery: "public-video-gallery",

  // Performer Routes
  publicPerformerDetails: "public-performer-details",
  performerDetails: "performer-details",
  publicPerformers: "public-performers",
  performers: "performers",

  // TODO: Can be removed in the future once it's certain that the feature won't be re-used.
  // See: #865d825tt #865cxfbj5
  performerWorkWithMe: "performer-work-with-me",

  // Job (Performer)
  performerPublicUploadVideo: "performer-upload-video",

  // Quotation (Pricing Calculator)
  quote: "quote",
  quoteSummary: "quote-summary",
  quoteInvoice: "quote-invoice",

  // Order : List
  orders: "orders",
  // Order : Detail
  orderDetail: "order-detail",
  // Order : Detail : Actions
  orderShippingDetails: "order-shipping-details",
  requests: "requests",
  reviewRequests: "review-requests",
  downloadRequests: "download-requests",
  performerSelect: "performer-select",
  performerApplications: "performer-applications",

  // Creator content
  creatorContentSubmit: "creator-content-submit",
  creatorContentSearch: "creator-content-search",
  creatorContentCart: "creator-content-cart",
  creatorContentSummary: "creator-content-summary",
  creatorContentThankYou: "creator-content-thank-you",

  ...sharedRouteNames,
};
