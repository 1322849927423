import { profileGql } from "@/api/profile/profile";
import {
  Profile,
  Profile_profile_User,
  Profile_profile_User_videoCredit,
} from "@/api/profile/__generated__/Profile";
import { useQuery } from "@vue/apollo-composable";
import { computed } from "vue";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { apiErrorCodes } from "@/shared/utils/constants";

export const useProfile = () => {
  const {
    result: profileResult,
    loading: profileLoading,
    refetch: fetchProfile,
  } = useQuery<Profile>(
    profileGql,
    {},
    {
      fetchPolicy: "network-only",
    }
  );

  const profileParsedResponse = computed(() => {
    return parseGqlResponse<Profile_profile_User>(
      "User",
      profileResult.value,
      apiErrorCodes.INVALID_AUTH_TOKEN
    );
  });

  const profile = computed(() => profileParsedResponse.value.data);
  const profileError = computed(() => profileParsedResponse.value.error);

  const videoCredits = computed<
    Profile_profile_User_videoCredit | null | undefined
  >(() => profileParsedResponse.value.data?.videoCredit);

  return { profile, profileLoading, profileError, fetchProfile, videoCredits };
};
