import gql from "graphql-tag";

export const checkoutCreatorContentGql = gql`
  mutation CheckoutCreatorContent($input: CheckoutCreatorContentInput!) {
    checkoutCreatorContent(input: $input) {
      ... on GenericSuccess {
        success
        message
      }

      ... on ResponseErrors {
        errors {
          code
          displayMessage
          message
        }
      }
    }
  }
`;
