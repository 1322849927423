
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useGlobalModal } from "@/shared/composables/useGlobalModal";
import { useHowToOrder } from "@/shared/composables/useHowToOrder";

export default defineComponent({
  setup() {
    const { t } = useI18n();

    const { isGlobalModalVisible, openGlobalModal, closeGlobalModal } =
      useGlobalModal("HOW_TO_ORDER");

    const { isFirstLogin } = useHowToOrder();

    const videoUrl = computed(() => {
      const autoplayParams = isFirstLogin.value ? "&autoplay=true" : "";
      return (
        "https://www.loom.com/embed/8aed33e5c46f4d41b65b7fd7cc7db2ce?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" +
        autoplayParams
      );
    });

    return {
      t,
      isGlobalModalVisible,
      openGlobalModal,
      closeGlobalModal,
      videoUrl,
    };
  },
});
