import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"position":"relative","padding-bottom":"62.5%","height":"0"} }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    width: "1000px",
    visible: _ctx.isGlobalModalVisible,
    title: _ctx.t('How to Order UGC Videos?'),
    footer: null,
    onCancel: _ctx.closeGlobalModal,
    "destroy-on-close": "",
    "mask-closable": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("iframe", {
          src: _ctx.videoUrl,
          frameborder: "0",
          webkitallowfullscreen: "",
          mozallowfullscreen: "",
          allowfullscreen: "",
          style: {"position":"absolute","top":"0","left":"0","width":"100%","height":"100%"}
        }, null, 8, _hoisted_2)
      ])
    ]),
    _: 1
  }, 8, ["visible", "title", "onCancel"]))
}