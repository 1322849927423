/** See: https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk */

import { config } from "@/shared/utils/config";

export const loadHubspotChatWidget = () => {
  if (!config.isHubspotChatWidgetEnabled) return;

  try {
    console.log("Hubspot: Chat initializing...");

    setTimeout(() => {
      const _window = window as any;
      if (_window.HubSpotConversations) {
        _window.HubSpotConversations.widget.load();
        console.log("Hubspot: Chat initialized.");
      }
    }, 1000);
  } catch (e) {
    console.error("Error in loading Hubspot widget: ", e);
  }
};

export const removeHubspotChatWidget = () => {
  if (!config.isHubspotChatWidgetEnabled) return;

  try {
    const _window = window as any;
    if (_window.HubSpotConversations) {
      _window.HubSpotConversations.widget.remove();
    }
    console.log("Hubspot: Chat removed.");
  } catch (e) {
    console.error("Error in removing Hubspot widget: ", e);
  }
};
