
import { computed, defineComponent } from "vue";
import { useFileDownloaderModal } from "@/shared/composables/useFileDownloaderModal";
import { DownloadOutlined } from "@ant-design/icons-vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n();

    const {
      hasError,
      isDone,
      isDownloading,
      openGlobalModal,
      closeGlobalModal,
      isGlobalModalVisible,
      downloadProgressData,
      isPopUpVisible,
      cancelDownload,
      showIndividualProgressBars,
      totalProgressData,
      currentDownloadFilename,
    } = useFileDownloaderModal();

    const progressBarStatus = computed(() => {
      if (isDownloading.value) {
        return "active";
      } else if (hasError.value) {
        return "exception";
      }

      return "";
    });

    return {
      t,
      progressBarStatus,

      totalProgressData,
      hasError,
      isDone,
      isDownloading,
      openGlobalModal,
      closeGlobalModal,
      isGlobalModalVisible,
      downloadProgressData,
      isPopUpVisible,
      cancelDownload,
      showIndividualProgressBars,
      currentDownloadFilename,
    };
  },
  components: { DownloadOutlined },
});
