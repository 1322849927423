
import { defineComponent, watchPostEffect } from "vue";
import BrandIcon from "@/shared/components/Icons/BrandIcon.vue";
import routeNames from "@/web/router/routeNames";
import ShoppingCartOutlined from "@ant-design/icons-vue/ShoppingCartOutlined";
import UserOutlined from "@ant-design/icons-vue/UserOutlined";
import MenuOutlined from "@ant-design/icons-vue/MenuOutlined";
import { useProfile } from "@/shared/composables/useProfile";
import { useBusinessProfile } from "@/shared/composables/useBusinessProfile";
import { useGlobalModal } from "@/shared/composables/useGlobalModal";
import { useAuthentication } from "@/shared/composables/useAuthentication";
import { useI18n } from "vue-i18n";
import { useWindowSizeChecker } from "@/shared/utils/windowSizeChecker";
import { LoadingOutlined } from "@ant-design/icons-vue";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";
import { config } from "@/shared/utils/config";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const { isLaptop } = useWindowSizeChecker();
    const { redirectToVideoDetails, redirectToHomepage } = useRedirectHelper();

    const { openGlobalModal } = useGlobalModal("HOW_TO_ORDER");

    /**
     * // FIXME: Hide errors and refetch for workaround only on missing header when token expires.
     * `profile` and `businessProfile` queries fails even with `isAuthenticated` check on components, hence
     * we get no icons showing. This only happens when redirecting to `Home.vue` after a shouldLogout event,
     * and not on consecutive reloads of the page. Also only happens in `Home.vue` and not on other views.
     *
     * See: #3gaydgp
     */
    const { profile, profileLoading, profileError, fetchProfile } =
      useProfile();
    const { businessProfile, businessProfileLoading, fetchBusinessProfile } =
      useBusinessProfile();
    const { isAuthenticated, handleLogout, logoutLoading } =
      useAuthentication();

    watchPostEffect(async () => {
      if (isAuthenticated && !profile.value) {
        await fetchProfile();
        await fetchBusinessProfile();
      }
    });
    /** END OF WORKAROUND - See: #3gaydgp */

    const handleOrderNowCta = () => {
      if (config.customVideoIdeaId) {
        redirectToVideoDetails({ videoId: config.customVideoIdeaId });
      } else {
        redirectToHomepage();
      }
    };

    return {
      t,
      routeNames,
      profile,
      profileLoading,
      profileError,
      businessProfile,
      businessProfileLoading,
      isLaptop,
      openGlobalModal,
      handleLogout,
      logoutLoading,
      handleOrderNowCta,
    };
  },
  components: {
    BrandIcon,
    ShoppingCartOutlined,
    UserOutlined,
    MenuOutlined,
    LoadingOutlined,
    AppIcon,
  },
});
