export const openInNewTab = (url?: string) => {
  if (!window || !url) return;

  window.open(url, "_blank");
};

/**
 * Timeout utility useful for async/await
 * @param delayMs
 * @returns
 */
export const sleep = (delayMs = 100) =>
  new Promise((resolve) => setTimeout(resolve, delayMs));

/**
 * Scrolls back to top of page.
 * @param behavior Scroll behavior. 'auto' or 'smoooth'
 */
export const scrollToTop = (behavior: ScrollBehavior = "smooth") => {
  scrollTo({ top: 0, left: 0, behavior: behavior });
};

/**
 * Scrolls back to top of a certain element.
 * @param behavior Scroll behavior. 'auto' or 'smoooth'
 */
export const scrollToElement = (
  elementId,
  behavior: ScrollBehavior = "smooth",
  offset = 0
) => {
  const element = document.getElementById(elementId);
  const elementDistanceFromTopOfScreen =
    element?.getBoundingClientRect().top ?? 0;
  const distanceFromTop =
    window.scrollY + elementDistanceFromTopOfScreen + offset;
  scrollTo({ top: distanceFromTop, behavior });
};

/** From: https://github.com/atomantic/is-ua-webview */
const WEBVIEW_REGEX_RULES: RegExp[] = [
  // if it says it's a webview, let's go with that
  /WebView/i,
  // iOS webview will be the same as safari but missing "Safari"
  // FIXME: Fix detecting embedded on Safari. See: #865d1f9w2
  // /(iPhone|iPod|iPad)(?!.*Safari)/i,
  // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
  // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
  /Android.*(;\s+wv|Version\/\d.\d\s+Chrome\/\d+(\.0){3})/i,
  // old chrome android webview agent
  /Linux; U; Android/i,
];

/** Detect if in a Webview browser. */
export const isPossibleWebview = (userAgent?: string): boolean => {
  const userAgentToTest = userAgent ?? window.navigator.userAgent ?? "";

  const webviewRegExpMatched = WEBVIEW_REGEX_RULES.some((regex) =>
    regex.test(userAgentToTest)
  );

  const isMobile =
    /(Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile)/gi;

  return isMobile && webviewRegExpMatched;
};

/**
 * Checks if URL includes host string. Returns `false` if not a valid URL.
 *
 * @param url Entire URL string.
 * @param host Host string to check against.
 */
export const checkURLHost = (url: string, host: string): boolean => {
  try {
    const urlObj = new URL(url);
    return urlObj.host.includes(host);
  } catch (e) {
    return false;
  }
};

/**
 * Copies a text string to clipboard.
 * @returns If successful or not
 */
export const copyText = (text: string): boolean => {
  // Try use navigator API
  try {
    navigator.clipboard.writeText(text);
    return true;
  } catch (e) {
    console.error(
      "Error in copying text using navigator.clipboard.writeText",
      e
    );
  }

  // Fallback using execCommand
  const textarea = document.createElement("textarea");
  textarea.style.top = "-1000px";
  textarea.style.left = "-1000px";
  textarea.style.position = "fixed";
  textarea.value = text;

  document.body.appendChild(textarea);
  textarea.focus();
  textarea.select();

  try {
    const success = document.execCommand("copy");
    return success;
  } catch (e) {
    console.error(
      "Error in copying text using document.execCommand('copy')",
      e
    );

    return false;
  } finally {
    document.body.removeChild(textarea);
  }
};
