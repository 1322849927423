import { makeToast } from "@/shared/utils/toast";
import isEmpty from "lodash/isEmpty";
import isPlainObject from "lodash/isPlainObject";
import { apiErrorCodes } from "@/shared/utils/constants";
import { hasResponseErrorCode } from "./responseParser";

export type GqlError = {
  isValid: boolean;
  message: string | null;
  errors: {
    message: string;
    code?: string;
    __typename: string;
  }[];
};

export type CartItemLimitExceededError = {
  code: number;
  message: string;
  displayMessage?: string;
  limit: number;
};

/**
 * Use this to parse error from GQL response. Errors are not handled
 * on apollo link since we are using a custom union error responses
 *
 * All custom error message based on code should be handled here
 *
 * @deprecated
 */
export const handleGqlError = (
  response,
  successFunction: () => void
): { isValid: boolean } => {
  const result = { isValid: true };

  if (isPlainObject(response?.data)) {
    for (const responseItem in response?.data) {
      const responseItemValue = response?.data[responseItem];

      if (!isEmpty(responseItemValue?.errors)) {
        /// TODO Add logic here to customize message based on error code if needed
        /// TODO Apply localization once it is supported outside of setup function
        makeToast("error", "Error", responseItemValue?.errors[0].message);
        result.isValid = false;
      }
    }
  }

  if (successFunction && result.isValid) {
    successFunction();
  }

  return result;
};

export enum HideIfErrorCode {
  ALL_ERRORS = "*",
}

/**
 * Use this to parse error from GQL response. Errors are not handled
 * on apollo link since we are using a custom union error responses
 *
 * All custom error message based on code should be handled here
 *
 * To be used in conjunction with [parseGqlResponse]
 */
export const parseGqlError = (response, hideIfErrorCode?): GqlError => {
  const result = { isValid: true, message: null, errors: [] };

  if (!isEmpty(response?.errors)) {
    /// TODO Apply localization once it is supported outside of setup function
    if (
      hideIfErrorCode !== HideIfErrorCode.ALL_ERRORS &&
      (hideIfErrorCode == null || response?.errors[0].code != hideIfErrorCode)
    ) {
      /// TODO Add logic here to customize message based on error code if needed

      if (hasResponseErrorCode(response, apiErrorCodes.INVALID_AUTH_TOKEN)) {
        /// Don't show toast message globally on INVALID_AUTH_TOKEN
        /// Handled in `errorHandlerLink.ts` (auto logout on SESSION_EXPIRED)
      } else if (
        hasResponseErrorCode(
          response,
          apiErrorCodes.CART_ITEM_LIMIT_EXCEEDED_ERROR
        )
      ) {
        const error = response?.errors[0] as CartItemLimitExceededError;

        makeToast(
          "error",
          "Error",
          `You're checking out more than your current subscription plan. Please upgrade your subscription or check out up to ${error.limit} videos only.`
        );
      } else if (
        hasResponseErrorCode(
          response,
          apiErrorCodes.CART_ITEM_MIN_LIMIT_NOT_REACHED_ERROR
        )
      ) {
        const error = response?.errors[0] as CartItemLimitExceededError;

        makeToast(
          "error",
          "Error",
          `Please select a minimum of ${error.limit} videos.`
        );
      } else if (
        hasResponseErrorCode(
          response,
          apiErrorCodes.JOB_NOT_ACCEPTING_VIDEO_ERROR
        )
      ) {
        makeToast(
          "error",
          "Error",
          "This Job is not accepting a video submission. If you have to submit, please approach your manager."
        );
      } else {
        makeToast("error", "Error", response?.errors[0].message);
      }

      // For FE debugging, trace where error is triggered
      console.trace();
    }

    result.isValid = false;
    result.message = response?.errors[0].message;
    result.errors = response?.errors;
  }

  return result;
};
