import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-layout__background z-0" }
const _hoisted_2 = { class: "flex-1" }
const _hoisted_3 = { class: "page-layout__header__subtitle" }
const _hoisted_4 = {
  key: 0,
  class: "page-layout__header__title-action"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeftOutlined = _resolveComponent("LeftOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_StepBar = _resolveComponent("StepBar")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, {
    class: _normalizeClass(["page-layout relative", [
      _ctx.narrow && 'page-layout--narrow',
      _ctx.narrower && 'page-layout--narrower',
      _ctx.narrowest && 'page-layout--narrowest',
    ]])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "complexBackground")
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["page-layout__container z-10", [_ctx.fullWidth && 'fluid']])
      }, [
        _renderSlot(_ctx.$slots, "header", {}, () => [
          (_ctx.$slots['title-navigation'] || _ctx.backButton)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass([_ctx.fullWidth && 'fluid', "page-layout__navigation"])
              }, [
                _renderSlot(_ctx.$slots, "title-navigation", {}, () => [
                  (_ctx.backButton)
                    ? (_openBlock(), _createBlock(_component_a_button, {
                        key: 0,
                        size: "small",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-back'))),
                        class: "page-layout__navigation-back-button"
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_LeftOutlined)
                        ]),
                        default: _withCtx(() => [
                          _createTextVNode(" " + _toDisplayString(_ctx.t("Go Back")), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _renderSlot(_ctx.$slots, "title-navigation-right")
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.stepBarOptions)
            ? (_openBlock(), _createBlock(_component_StepBar, {
                key: 1,
                stepBarOptions: _ctx.stepBarOptions
              }, null, 8, ["stepBarOptions"]))
            : _createCommentVNode("", true),
          (_ctx.$slots['title'] || _ctx.$slots['subtitle'] || _ctx.title || _ctx.subtitle)
            ? (_openBlock(), _createBlock(_component_a_layout_header, {
                key: 2,
                class: _normalizeClass([[
            _ctx.fullWidth && 'fluid',
            (_ctx.$slots['title-navigation'] || _ctx.backButton) &&
              'has-title-navigation',
          ], "page-layout__header flex"])
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _renderSlot(_ctx.$slots, "title", {}, () => [
                      (_ctx.title)
                        ? (_openBlock(), _createBlock(_component_a_typography_title, {
                            key: 0,
                            level: 2
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.title), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_3, [
                      _renderSlot(_ctx.$slots, "subtitle", {}, () => [
                        (_ctx.subtitle)
                          ? (_openBlock(), _createBlock(_component_a_typography_paragraph, { key: 0 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.subtitle), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]),
                  (_ctx.$slots['title-action'])
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _renderSlot(_ctx.$slots, "title-action")
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 3
              }, 8, ["class"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_a_layout_content, {
          class: _normalizeClass([_ctx.fullWidth && 'fluid', "page-layout__content"])
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 8, ["class"])
      ], 2)
    ]),
    _: 3
  }, 8, ["class"]))
}