
import {
  makeEmailRule,
  makeRequiredRule,
} from "@/shared/utils/validators/commonValidators";
import { defineComponent, reactive } from "vue";
import { useI18n } from "vue-i18n";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import { useNewsletter } from "../composables/useNewsletter";
import message from "@/shared/utils/message";

export default defineComponent({
  setup() {
    const { t } = useI18n();

    const { subscribeToNewsletter, subscribeToNewsletterLoading } =
      useNewsletter();

    const formState = reactive({
      email: "",
    });

    const handleSubmit = async () => {
      try {
        await subscribeToNewsletter({ input: { email: formState.email } });

        // Reset form
        formState.email = "";
      } catch (e: any) {
        message["info"]({
          content: e.message,
        });

        console.error(e);
      }
    };

    const handleFailed = ({ errorFields }) => {
      message["error"]({
        content: errorFields.flatMap((e) => e.errors).join("\n"),
      });
    };

    return {
      t,
      formState,
      subscribeToNewsletterLoading,
      handleSubmit,
      handleFailed,
    };
  },
  components: {
    FormGroup,
    InputText,
  },
  methods: {
    makeEmailRule,
    makeRequiredRule,
  },
});
