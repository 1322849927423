import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "layout-authenticated-header__top flex justify-end items-center gap-6"
}
const _hoisted_2 = { class: "inline-flex items-center" }
const _hoisted_3 = { class: "layout-authenticated-header__bottom flex justify-between items-center" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "flex gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShoppingCartOutlined = _resolveComponent("ShoppingCartOutlined")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_LoadingOutlined = _resolveComponent("LoadingOutlined")!
  const _component_UserOutlined = _resolveComponent("UserOutlined")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_BrandIcon = _resolveComponent("BrandIcon")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_MenuOutlined = _resolveComponent("MenuOutlined")!

  return (!_ctx.isLaptop)
    ? (_openBlock(), _createBlock(_component_a_layout_header, {
        key: 0,
        class: "layout-authenticated-header"
      }, {
        default: _withCtx(() => [
          (_ctx.profile)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_router_link, {
                  to: { name: _ctx.routeNames.cart },
                  class: "layout-authenticated-header__cart"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ShoppingCartOutlined),
                    _createVNode(_component_a_badge, {
                      count: _ctx.profile.cartItemCount
                    }, null, 8, ["count"])
                  ]),
                  _: 1
                }, 8, ["to"]),
                (_ctx.profile)
                  ? (_openBlock(), _createBlock(_component_a_dropdown, {
                      key: 0,
                      loading: _ctx.logoutLoading,
                      "overlay-class-name": "layout-authenticated-header__dropdown"
                    }, {
                      overlay: _withCtx(() => [
                        _createVNode(_component_a_menu, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_menu_item, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_router_link, {
                                  to: { name: _ctx.routeNames.profile }
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.t("My Order")), 1)
                                  ]),
                                  _: 1
                                }, 8, ["to"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_menu_item, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_router_link, {
                                  to: { name: _ctx.routeNames.kyc }
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.t("Profile")), 1)
                                  ]),
                                  _: 1
                                }, 8, ["to"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_menu_item, { onClick: _ctx.handleLogout }, {
                              default: _withCtx(() => [
                                _createVNode(_component_AppIcon, {
                                  name: "logout",
                                  class: "w-6 h-6 mr-1"
                                }),
                                _createTextVNode(" " + _toDisplayString(_ctx.t("Logout")), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("a", {
                          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
                        }, [
                          _createElementVNode("span", _hoisted_2, [
                            (_ctx.logoutLoading)
                              ? (_openBlock(), _createBlock(_component_LoadingOutlined, { key: 0 }))
                              : (_openBlock(), _createBlock(_component_UserOutlined, { key: 1 })),
                            (_ctx.businessProfile?.nickname || _ctx.profile.name)
                              ? (_openBlock(), _createBlock(_component_a_typography_paragraph, {
                                  key: 2,
                                  ellipsis: { rows: 1 },
                                  class: "ml-2 mb-0 hidden xs:inline-block layout-authenticated-header__menu-profile__name"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.businessProfile?.nickname || _ctx.profile.name), 1)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]),
                      _: 1
                    }, 8, ["loading"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_link, {
              to: { name: _ctx.routeNames.home },
              class: "layout-authenticated-header__brand-logo"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BrandIcon)
              ]),
              _: 1
            }, 8, ["to"]),
            (_ctx.profile && !_ctx.profileLoading && !_ctx.businessProfileLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_a_space, { size: 32 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_router_link, {
                        to: { name: _ctx.routeNames.home, query: { view: 'SEARCH' } },
                        class: "flex items-center gap-2"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Video Ideas")) + " ", 1),
                          _createVNode(_component_a_badge, {
                            count: _ctx.t('BETA')
                          }, null, 8, ["count"])
                        ]),
                        _: 1
                      }, 8, ["to"]),
                      _createVNode(_component_router_link, {
                        to: { name: _ctx.routeNames.quote }
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Get a Quote")), 1)
                        ]),
                        _: 1
                      }, 8, ["to"]),
                      _createElementVNode("a", {
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openGlobalModal && _ctx.openGlobalModal(...args)))
                      }, _toDisplayString(_ctx.t("How to Order UGC Videos?")), 1),
                      _createVNode(_component_router_link, {
                        to: { name: _ctx.routeNames.creatorContentSearch }
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Pre-Made Videos")), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _createVNode(_component_a_button, {
                type: "primary",
                class: "gradient-blue",
                onClick: _ctx.handleOrderNowCta
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Order Now")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_a_layout_header, {
        key: 1,
        class: "layout-authenticated-header layout-authenticated-header--mobile flex justify-between items-center"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, {
            to: { name: _ctx.routeNames.home },
            class: "layout-authenticated-header__brand-logo"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BrandIcon)
            ]),
            _: 1
          }, 8, ["to"]),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.profile)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: { name: _ctx.routeNames.cart },
                  class: "layout-authenticated-header__cart"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ShoppingCartOutlined),
                    _createVNode(_component_a_badge, {
                      count: _ctx.profile.cartItemCount
                    }, null, 8, ["count"])
                  ]),
                  _: 1
                }, 8, ["to"]))
              : _createCommentVNode("", true),
            _createVNode(_component_a_dropdown, {
              loading: _ctx.logoutLoading,
              "overlay-class-name": "layout-authenticated-header__dropdown layout-authenticated-header__dropdown--mobile"
            }, {
              overlay: _withCtx(() => [
                _createVNode(_component_a_menu, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_menu_item, { class: "hover:bg-transparent text-center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          type: "primary",
                          class: "gradient-blue layout-authenticated-header__order-now",
                          onClick: _ctx.handleOrderNowCta
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("Order Now")), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, {
                          to: { name: _ctx.routeNames.home, query: { view: 'SEARCH' } },
                          class: "flex items-center gap-2"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(_ctx.t("Video Ideas")), 1),
                            _createVNode(_component_a_badge, {
                              count: _ctx.t('BETA')
                            }, null, 8, ["count"])
                          ]),
                          _: 1
                        }, 8, ["to"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, {
                          to: { name: _ctx.routeNames.quote }
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("Get a Quote")), 1)
                          ]),
                          _: 1
                        }, 8, ["to"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, null, {
                      default: _withCtx(() => [
                        _createElementVNode("a", {
                          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.openGlobalModal && _ctx.openGlobalModal(...args)))
                        }, _toDisplayString(_ctx.t("How to Order UGC Videos?")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, {
                          to: { name: _ctx.routeNames.creatorContentSearch }
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("Pre-Made Videos")), 1)
                          ]),
                          _: 1
                        }, 8, ["to"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, {
                          to: { name: _ctx.routeNames.profile }
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("My Order")), 1)
                          ]),
                          _: 1
                        }, 8, ["to"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, {
                          to: { name: _ctx.routeNames.kyc }
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("Profile")), 1)
                          ]),
                          _: 1
                        }, 8, ["to"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, { onClick: _ctx.handleLogout }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AppIcon, {
                          name: "logout",
                          class: "w-6 h-6 mr-1"
                        }),
                        _createElementVNode("span", null, _toDisplayString(_ctx.t("Logout")), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("a", {
                  onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
                }, [
                  _createVNode(_component_MenuOutlined)
                ])
              ]),
              _: 1
            }, 8, ["loading"])
          ])
        ]),
        _: 1
      }))
}