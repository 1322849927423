export const GA_ACTIONS = {
  /**
   * Cart actions uses Enhanced e-commerce actions
   * https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#action-types
   */
  CART_VIEW_ITEM: "view_item",
  CART_ADD_ITEM: "add_to_cart",
  CART_DELETE_ITEM: "remove_from_cart",
  CART_CLEAR: "cart_clear",
  CART_CHECKOUT_START: "begin_checkout",
  CART_CHECKOUT_FINISH: "purchase",
  LOGIN_GOOGLE: "login via google",
  LOGOUT: "logout",
  KYC_START: "kyc start",
  KYC_CONTINUE: "kyc continue",
  KYC_SKIP: "kyc skip",
  KYC_FINISH: "kyc finish",
  SUBSCRIPTION_ADD: "subscription add",
  SUBSCRIPTION_CHANGE: "subscription change",
  /**
   * Search action uses google default analytics events
   * https://developers.google.com/analytics/devguides/collection/gtagjs/events
   */
  SEARCH: "search",
  SEARCH_LOAD_MORE: "search_load_more",
  /**
   * Custom events
   * https://support.google.com/analytics/answer/12229021?hl=en
   */
  VIDEO_REVISE_SCRIPT: "revised_script",
  VIDEO_GALLERY_TERM_CLICK: "video_gallery_term_click",
  PUBLIC_CREATOR_LEAD: "public_creator_lead",
};

export const GA_KYC_STEP_LABELS = [
  "kyc step 1 - let's get to know each other",
  "kyc step 2 - let's get social",
  "kyc step 3 - about your product",
  "kyc step 4 - target audience",
];

export const GA_CATEGORY = {
  AUTHENTICATION: "authentication",
  ONBOARDING: "onboarding",
  ECOMMERCE: "ecommerce",
  SUBSCRIPTION: "subscription",
};

/** See: https://support.google.com/analytics/answer/2709828?hl=en#zippy=%2Cin-this-article */
export const GA_CUSTOM_DIMENSIONS = {
  video_gallery_term: "video_gallery_term",
  creator_id: "creator_id",
};
