import { config } from "@/shared/utils/config";
import { RouteLocationNormalized } from "vue-router";
import routeNames from "@/web/router/routeNames";

/** Disable Meta Pixel if id is not provided */
const ENABLE_META_PIXEL = !!config.facebookMetaPixelId;

export type MetaPixelEventType = "track" | "trackCustom";

/** Valid standard event names, from: https://developers.facebook.com/docs/meta-pixel/reference#standard-events */
export type MetaPixelStandardEventName =
  | "AddPaymentInfo"
  | "AddToCart"
  | "AddToWishlist"
  | "CompleteRegistration"
  | "Contact"
  | "CustomizeProduct"
  | "Donate"
  | "FindLocation"
  | "InitiateCheckout"
  | "Lead"
  | "Purchase"
  | "Schedule"
  | "Search"
  | "StartTrial"
  | "SubmitApplication"
  | "Subscribe"
  | "ViewContent";

/** Event name depending if custom type or not. Custom type accepts custom names. */
export type MetaPixelEventName<T extends MetaPixelEventType> = T extends "track"
  ? MetaPixelStandardEventName
  : string;

/** From: https://developers.facebook.com/docs/meta-pixel/reference/ */
export type MetaPixelEventParameters = Partial<{
  content_ids: (string | number)[];
  content_name: string;
  content_type: "product" | "product_group";
}>;

/**
 * Track event through Facebook Meta Pixel
 * @param eventType Either "track" (standard events), or "trackCustom" (custom events)
 * @param eventName Name of the event to track
 * @param eventProperties Optional object properties to send along the event.
 * See: https://developers.facebook.com/docs/meta-pixel/reference#standard-events
 */
export const metaPixelTrackEvent = <T extends MetaPixelEventType>(
  eventType: T,
  eventName: MetaPixelEventName<T>,
  eventProperties?: MetaPixelEventParameters
): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _window = window as any;

  // If tracking is disabled, or fbq has not loaded in window
  if (!ENABLE_META_PIXEL || !_window.fbq) {
    return;
  }

  _window.fbq(eventType, eventName, eventProperties);
};

export const metaPixelTrackPage = (to: RouteLocationNormalized) => {
  // TODO: Do tracking here based on page, if custom data is not needed.
  // If so, then do tracking on Component or View.
  // See: #865bgxenh

  /** Mapping of routeNames to custom events it triggers */
  const routeCustomEventMap: Record<string, string> = {
    [routeNames.login]: "ColdAudienceView",
    [routeNames.home]: "WarmAudienceLead",
    [routeNames.performerSelect]: "ChoosePerformers",
    [routeNames.thankYou]: "Submitted",
  };

  const customEvent = routeCustomEventMap[to.name?.toString() ?? ""] ?? null;

  if (customEvent) {
    metaPixelTrackEvent("trackCustom", customEvent);
  }
};
