import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, Teleport as _Teleport, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_LayoutAuthenticated = _resolveComponent("LayoutAuthenticated")!
  const _component_LayoutNonAuthenticated = _resolveComponent("LayoutNonAuthenticated")!
  const _component_HowToOrderModal = _resolveComponent("HowToOrderModal")!
  const _component_AskForQuotationModal = _resolveComponent("AskForQuotationModal")!
  const _component_LearnMoreModal = _resolveComponent("LearnMoreModal")!
  const _component_FileDownloaderModal = _resolveComponent("FileDownloaderModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isAuthenticated)
      ? (_openBlock(), _createBlock(_component_LayoutAuthenticated, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view, null, {
              default: _withCtx(({ Component }) => [
                (_openBlock(), _createBlock(_KeepAlive, {
                  include: [
          _ctx.routeNames.home,
          _ctx.routeNames.performerSelect,
          _ctx.routeNames.performers,
          _ctx.routeNames.publicVideoGallery,
        ]
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                    key: _ctx.$route.fullPath
                  }))
                ], 1032, ["include"]))
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_LayoutNonAuthenticated, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view, null, {
              default: _withCtx(({ Component }) => [
                (_openBlock(), _createBlock(_KeepAlive, {
                  include: [_ctx.routeNames.publicVideoGallery]
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                ], 1032, ["include"]))
              ]),
              _: 1
            })
          ]),
          _: 1
        })),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_HowToOrderModal)
    ])),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_AskForQuotationModal)
    ])),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_LearnMoreModal)
    ])),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_FileDownloaderModal)
    ]))
  ], 64))
}