import { useMatchMedia } from "vue-composable";

/** A composable that exposes refs to detect current screen size
 * Only uses min-width so that i.e. isDesktop would return true even for larger screens
 */
export const useWindowSizeChecker = () => {
  const isLargeDesktop = useMatchMedia("(min-width: 1600px)").matches;
  const isDesktop = useMatchMedia("(max-width: 1599px)").matches;
  const isLaptop = useMatchMedia("(max-width: 1199px)").matches;
  const isTablet = useMatchMedia("(max-width: 991px)").matches;
  const isMobile = useMatchMedia("(max-width: 767px)").matches;
  const isXs = useMatchMedia("(max-width: 575px)").matches;

  return {
    isLargeDesktop,
    isDesktop,
    isLaptop,
    isTablet,
    isMobile,
    isXs,
  };
};
