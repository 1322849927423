import gql from "graphql-tag";

export const businessProfileGql = gql`
  query BusinessProfile {
    businessProfile {
      ... on BusinessProfile {
        id
        created
        modified
        pendingStep
        nickname
        productType
        industry {
          id
          name
        }
        industryOther
        companyWebsiteUrl
        facebookUrl
        instagramUrl
        tiktokUrl
        product {
          id
          name
          industry {
            id
            name
          }
        }
        productDescription
        productAdjectives
        productFeelings
        targetGender
        targetMinAge
        targetMaxAge
        targetAudienceDescription
        phoneNumber
        orderReasons
        orderReasonOther
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
