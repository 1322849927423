
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import { useGlobalModal } from "@/shared/composables/useGlobalModal";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";
import routeNames from "@/web/router/routeNames";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const router = useRouter();

    const { isGlobalModalVisible, openGlobalModal, closeGlobalModal } =
      useGlobalModal("ASK_FOR_QUOTATION");

    const { redirectToQuote } = useRedirectHelper();

    const handleCancel = async () => {
      await router.push({ name: routeNames.cart });
      closeGlobalModal();
    };

    const handleConfirm = async () => {
      redirectToQuote();
      closeGlobalModal();
    };

    return {
      t,

      isGlobalModalVisible,
      openGlobalModal,
      closeGlobalModal,

      handleConfirm,
      handleCancel,
    };
  },
  components: { AppIcon },
});
