import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "step-bar__step__title" }
const _hoisted_2 = { class: "step-bar__step__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_step = _resolveComponent("a-step")!
  const _component_a_steps = _resolveComponent("a-steps")!

  return (_openBlock(), _createBlock(_component_a_steps, {
    current: _ctx.stepBarOptions.currentStep,
    "label-placement": "vertical",
    class: _normalizeClass(['step-bar', 'step-bar--length-' + _ctx.stepBarOptions.steps.length])
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stepBarOptions.steps, (step) => {
        return (_openBlock(), _createBlock(_component_a_step, {
          key: step.key
        }, {
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, _toDisplayString(step.title), 1)
          ]),
          description: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(step.description), 1)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["current", "class"]))
}