import VueGtag, { event, GtagEvent } from "vue-gtag";
import { App } from "@vue/runtime-core";
import { RouteLocationNormalized } from "vue-router";
import startCase from "lodash/startCase";
import get from "lodash/get";
import { config } from "@/shared/utils/config";
import store from "@/web/store";
import queryString from "query-string";

/**
 * Remove other data to save memory space
 * And for easy read, update to Record<string, any> object
 */
const simplifyFilter = (
  options: { id: string; name: string }[] = []
): Record<string, string> => {
  return options?.reduce(
    (acc, curr) => ((acc[curr?.id ?? ""] = curr?.name), acc),
    {}
  );
};

const videosFilterStorageKey = "videos-filters";

/**
 * should only be used on gtag, create a composable if data needs to be reactive
 */
export const saveVideosFilterToStorage = ({ industry, type }) => {
  // For simplicity use industryId and typeId
  sessionStorage.setItem(
    videosFilterStorageKey,
    JSON.stringify({
      industryId: industry ? simplifyFilter(industry) : {},
      typeId: type ? simplifyFilter(type) : {},
    })
  );
};

export const getVideosFilterPrettyName = (type, id) => {
  // get data saved on the local storage
  const data = JSON.parse(
    sessionStorage.getItem(videosFilterStorageKey) ?? "{}"
  );
  // try to get pretty name, return id if not found
  return get(data, [type, id], id);
};

export const gTagInit = (app: App): App => {
  if (!config.gtagEnabled) {
    return app;
  }

  return app.use(VueGtag, {
    config: {
      id: config.gtagId,
      enabled: config.gtagEnabled,
      appName: config.merchantBrand,
    },
    onReady() {
      console.log("GTAG Initialized");
    },
    onError() {
      console.error("GTAG encountered an error");
    },
  });
};

export const gTagEvent: GtagEvent = (action, eventParams) => {
  const params = eventParams ?? {};
  if (store.state.auth && store.state.auth.username) {
    params.user_id = store.state.auth.username;
  }
  event(action, params);
};

const searchTermsToBeautify = ["industryId", "typeId"];

/**
 * Format search term to be sent to GA
 *
 * @param searchFilter
 */
export const gtagSearchTermFormatter = (
  searchFilter: Record<string, unknown>
) => {
  const term = { ...searchFilter };
  try {
    searchTermsToBeautify.forEach((termKey) => {
      if (term?.[termKey]) {
        term[termKey] = getVideosFilterPrettyName(termKey, term?.industryId);
      }
    });
  } catch (error) {
    console.log(
      "Something went wrong when trying gtagSearchTermFormatter",
      error
    );
  }
  return term;
};

/**
 * Integrate this on router.afterEach
 *
 * Auto tracking is not working because of dynamic page import, manually send page view
 */
export const gTagPageViewTrack = (to: RouteLocationNormalized) => {
  let page_location = window.location.href;
  const { url, query } = queryString.parseUrl(window.location.href);

  // automatically inject other required parameters into the url query
  if (query.ref || query.referral) {
    query.utm_source = query.ref || query.referral; // use ref or referral code for the campaign source
    query.utm_medium = query.utm_medium ?? "referral"; // can't think of a better name for medium
    page_location = queryString.stringifyUrl({ url, query });
  }

  gTagEvent("page_view", {
    page_location,
    page_path: to.path,
    page_title: "Penguin" + startCase(to.name), // route name should be required on the routes
    send_page_view: true,
  });
};
