import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrandIcon = _resolveComponent("BrandIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_QuestionCircleOutlined = _resolveComponent("QuestionCircleOutlined")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!

  return (_openBlock(), _createBlock(_component_a_layout_header, { class: "layout-non-authenticated-header flex items-center justify-between" }, {
    default: _withCtx(() => [
      _createVNode(_component_router_link, {
        to: { name: _ctx.routeNames.home },
        class: "layout-non-authenticated-header__icon_container"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BrandIcon)
        ]),
        _: 1
      }, 8, ["to"]),
      _createElementVNode("a", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openGlobalModal && _ctx.openGlobalModal(...args)))
      }, [
        (_ctx.isMobile)
          ? (_openBlock(), _createBlock(_component_QuestionCircleOutlined, { key: 0 }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(_ctx.t("How to Order UGC Videos?")), 1)
            ], 64))
      ])
    ]),
    _: 1
  }))
}