import routeNames from "@/web/router/routeNames";
import { useRouter } from "vue-router";
import { compactObject } from "@/shared/utils/object";

export type HomeLayout = "HOME" | "SEARCH" | "FOR_YOU" | "RECENT";

export const useRedirectHelper = () => {
  const router = useRouter();

  const redirectToHomepage = (options?: {
    layout?: HomeLayout;
    search?: string;
    hash?: string;
  }) => {
    const search = options?.search;
    const layout: HomeLayout | undefined = options?.layout;

    return router.push({
      name: routeNames.home,
      query: { search, view: layout },
      hash: options?.hash,
    });
  };

  const redirectToKyc = (options?: { isCheckingOut?: boolean }) => {
    return router.push({
      name: routeNames.kyc,
      query: {
        isCheckingOut: options?.isCheckingOut
          ? String(options.isCheckingOut)
          : undefined,
      },
    });
  };

  const redirectToVideoDetails = (options: {
    videoId: string;
    cartItemId?: string;
    public?: boolean;
  }) => {
    return router.push({
      name: routeNames.videoDetails,
      params: { id: options.videoId },
      query: {
        cartItemId: options.cartItemId ? String(options.cartItemId) : undefined,
        public: options.public ? String(options.public) : undefined,
      },
    });
  };

  const redirectToPerformerDetails = (options: {
    id: string;
    orderId?: string;
    public?: boolean;
  }) => {
    if (options.public) {
      return router.push({
        name: routeNames.publicPerformerDetails,
        params: { id: options.id },
      });
    }

    return router.push({
      name: routeNames.performerDetails,
      params: { id: options.id },
      query: {
        orderId: options.orderId ? String(options.orderId) : undefined,
      },
    });
  };

  const redirectToOrderDetails = (options: { orderId: string }) => {
    return router.push({
      name: routeNames.orderDetail,
      params: {
        orderId: options.orderId,
      },
    });
  };

  /** @deprecated Use {@link redirectToPerformerApplications} instead for new performer selection flow. */
  const redirectToPerformerSelect = (options: { orderId?: string }) => {
    return router.push({
      name: routeNames.performerSelect,
      params: {
        orderId: options.orderId,
      },
    });
  };

  const redirectToPerformerApplications = (options: { orderId?: string }) => {
    return router.push({
      name: routeNames.performerApplications,
      params: {
        orderId: options.orderId,
      },
    });
  };

  const redirectToOrderShippingDetails = (options: { orderId?: string }) => {
    return router.push({
      name: routeNames.orderShippingDetails,
      params: {
        orderId: options.orderId,
      },
    });
  };

  const redirectToQuote = async (options?: {
    quotationId?: string;
    confirmed?: boolean;
  }) => {
    return router.push({
      name: routeNames.quote,
      query: {
        quotationId: options?.quotationId
          ? String(options.quotationId)
          : undefined,
        confirmed: options?.confirmed ? String(options.confirmed) : undefined,
      },
    });
  };

  const redirectToQuoteSummary = (options?: { quotationId?: string }) => {
    return router.push({
      name: routeNames.quoteSummary,
      query: {
        quotationId: options?.quotationId
          ? String(options.quotationId)
          : undefined,
      },
    });
  };

  const redirectToRequests = (options: {
    orderId?: string;
    requestId?: string;
    reviewing?: boolean;
  }) => {
    return router.push({
      name: routeNames.requests,
      params: { orderId: options.orderId },
      hash: options.requestId ? `#${options.requestId}` : undefined,
      query: {
        reviewing: options.reviewing ? String(options.reviewing) : undefined,
      },
    });
  };

  const redirectToReviewRequests = (options: { orderId?: string }) => {
    return router.push({
      name: routeNames.reviewRequests,
      params: { orderId: options.orderId },
    });
  };

  const redirectToDownloadRequests = (options: { orderId?: string }) => {
    return router.push({
      name: routeNames.downloadRequests,
      params: { orderId: options.orderId },
    });
  };

  const redirectToOrders = () => {
    return router.push({ name: routeNames.orders });
  };

  const redirectToDashboard = () => {
    return router.push({ name: routeNames.profile });
  };

  const redirectToCreatorContentSearch = (options?: {
    query?: string;
    creator?: string;
    hash?: string;
  }) => {
    return router.push({
      name: routeNames.creatorContentSearch,
      query: compactObject({
        brand: options?.query,
        by: options?.creator,
      }),
      hash: options?.hash ?? "",
    });
  };

  return {
    redirectToHomepage,
    redirectToQuote,
    redirectToQuoteSummary,
    redirectToRequests,
    redirectToKyc,
    redirectToVideoDetails,
    redirectToPerformerDetails,
    redirectToOrderDetails,
    redirectToPerformerSelect,
    redirectToPerformerApplications,
    redirectToOrderShippingDetails,
    redirectToReviewRequests,
    redirectToDownloadRequests,
    redirectToOrders,
    redirectToDashboard,
    redirectToCreatorContentSearch,
  };
};
