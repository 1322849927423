/**
 * For now we manually import both components and styles
 * since babel-plugin-import does not seem to work
 * with the current version of dependencies we have.
 */
import { App } from "@vue/runtime-core";
import Button from "ant-design-vue/lib/button";
import "ant-design-vue/lib/button/style";
import Anchor from "ant-design-vue/lib/anchor";
import "ant-design-vue/lib/anchor/style";
import Badge from "ant-design-vue/lib/badge";
import "ant-design-vue/lib/badge/style";
import Card from "ant-design-vue/lib/card";
import "ant-design-vue/lib/card/style";
import Carousel from "ant-design-vue/lib/carousel";
import "ant-design-vue/lib/carousel/style";
import Layout from "ant-design-vue/lib/layout";
import "ant-design-vue/lib/layout/style";
import Input from "ant-design-vue/lib/input";
import "ant-design-vue/lib/input/style";
import InputNumber from "ant-design-vue/lib/input-number";
import "ant-design-vue/lib/input-number/style";
import Form from "ant-design-vue/lib/form";
import "ant-design-vue/lib/form/style";
import Select from "ant-design-vue/lib/select";
import "ant-design-vue/lib/select/style";
import Switch from "ant-design-vue/lib/switch";
import "ant-design-vue/lib/switch/style";
import Menu from "ant-design-vue/lib/menu";
import "ant-design-vue/lib/menu/style";
import List from "ant-design-vue/lib/list";
import "ant-design-vue/lib/list/style";
import Tabs from "ant-design-vue/lib/tabs";
import "ant-design-vue/lib/tabs/style";
import Radio from "ant-design-vue/lib/radio";
import "ant-design-vue/lib/radio/style";
import AutoComplete from "ant-design-vue/lib/auto-complete";
import "ant-design-vue/lib/auto-complete/style";
import Dropdown from "ant-design-vue/lib/dropdown";
import "ant-design-vue/lib/dropdown/style";
import Checkbox from "ant-design-vue/lib/checkbox";
import "ant-design-vue/lib/checkbox/style";
import Slider from "ant-design-vue/lib/slider";
import "ant-design-vue/lib/slider/style";
import Space from "ant-design-vue/lib/space";
import "ant-design-vue/lib/space/style";
import Typography from "ant-design-vue/lib/typography";
import "ant-design-vue/lib/typography/style";
import Modal from "ant-design-vue/lib/modal";
import "ant-design-vue/lib/modal/style";
import Image from "ant-design-vue/lib/image";
import "ant-design-vue/lib/image/style";
import RangePicker from "ant-design-vue/lib/date-picker";
import "ant-design-vue/lib/date-picker/style";
import Progress from "ant-design-vue/lib/progress";
import "ant-design-vue/lib/progress/style";
import Alert from "ant-design-vue/lib/alert";
import "ant-design-vue/lib/alert/style";
import Avatar from "ant-design-vue/lib/avatar";
import "ant-design-vue/lib/avatar/style";
import Tooltip from "ant-design-vue/lib/tooltip";
import "ant-design-vue/lib/tooltip/style";
import Skeleton from "ant-design-vue/lib/skeleton";
import "ant-design-vue/lib/skeleton/style";
import Upload from "ant-design-vue/lib/upload";
import "ant-design-vue/lib/upload/style";
import Comment from "ant-design-vue/lib/comment";
import "ant-design-vue/lib/comment/style";
import Tag from "ant-design-vue/lib/tag";
import "ant-design-vue/lib/tag/style";
import Collapse from "ant-design-vue/lib/collapse";
import "ant-design-vue/lib/collapse/style";
import Rate from "ant-design-vue/lib/rate";
import "ant-design-vue/lib/rate/style";
import Steps from "ant-design-vue/lib/steps";
import "ant-design-vue/lib/steps/style";
import Result from "ant-design-vue/lib/result";
import "ant-design-vue/lib/result/style";
import Divider from "ant-design-vue/lib/divider";
import "ant-design-vue/lib/divider/style";
import { MenuOutlined } from "@ant-design/icons-vue";

/**
 * Type definitions for these components are not yet
 * updated to use .use() method.
 *
 * For now we can use the .component() method for
 * these components
 */
import { Row, Col } from "ant-design-vue/lib/grid";
import "ant-design-vue/lib/grid/style";

/**
 * Reference for the configurations for message component:
 * https://2x.antdv.com/components/message#API
 */
import { message } from "ant-design-vue";
import "ant-design-vue/lib/message/style";

message.config({
  duration: 5,
  top: "72px",
});

import notification from "ant-design-vue/lib/notification";
import "ant-design-vue/lib/notification/style";
notification.config({
  placement: "bottomRight",
  bottom: "50px",
  duration: 3,
});

export const useAntDesignComponents = (app: App): App => {
  return app
    .component(MenuOutlined.displayName, MenuOutlined)
    .component(Row.name, Row)
    .component(Col.name, Col)
    .use(Anchor)
    .use(Badge)
    .use(Button)
    .use(Card)
    .use(Carousel)
    .use(Layout)
    .use(Input)
    .use(InputNumber)
    .use(List)
    .use(Form)
    .use(Select)
    .use(Switch)
    .use(Tabs)
    .use(Menu)
    .use(Radio)
    .use(Dropdown)
    .use(AutoComplete)
    .use(Slider)
    .use(Space)
    .use(Checkbox)
    .use(Typography)
    .use(Modal)
    .use(Alert)
    .use(Progress)
    .use(Avatar)
    .use(RangePicker)
    .use(Skeleton)
    .use(Tooltip)
    .use(Image)
    .use(Upload)
    .use(Comment)
    .use(Tag)
    .use(Collapse)
    .use(Rate)
    .use(Steps)
    .use(Result)
    .use(Divider);
};
