import JSZip from "jszip";
import axios from "axios";

export type DownloadLink = {
  link: string;
  filename?: string;
};

export type DownloadedData = {
  data: Blob;
  filename?: string;
};

export const triggerDownloadViaAnchor = (data: DownloadedData) => {
  const linkElement = document.createElement("a");
  linkElement.href = URL.createObjectURL(data.data);
  linkElement.download = data.filename ?? "";
  linkElement.click();
  linkElement.remove();
};

export const downloadFile = async (
  data: DownloadLink,
  triggerBrowserDownload = false
): Promise<DownloadedData> => {
  console.group("[downloadViaFetch]");
  console.log("data: ", data);

  try {
    const response = await axios.get(data.link, {
      responseType: "blob",
    });

    console.log(response);

    const downloadedData: DownloadedData = {
      data: await response.data,
      filename: data.filename,
    };

    if (triggerBrowserDownload) {
      triggerDownloadViaAnchor(downloadedData);
    }

    return Promise.resolve(downloadedData);
  } catch (error) {
    const _error = error as any;

    console.error(_error);
    // See: AxiosError
    console.error(_error?.response);
    console.error(await _error?.response?.data?.text());
    console.error(_error?.message);

    console.groupEnd();

    return Promise.reject(error);
  }
};

/**
 * Downloads a list of files and compresses in ZIP.
 * @returns A boolean whether some downloaded files have errors.
 */
export const downloadFilesToZip = async (
  files: DownloadLink[],
  zipFileName?: string
): Promise<boolean> => {
  console.group("[downloadFilesZip]");
  console.log("files: ", files);
  console.log("zipFileName: ", zipFileName);

  const zip = new JSZip();

  const promises = await Promise.allSettled(
    files.map((file) => downloadFile(file))
  );

  promises.map((promise) => {
    if (promise.status === "fulfilled") {
      // Add to ZIP file
      try {
        zip.file(promise.value.filename ?? "", promise.value.data, {
          base64: true,
        });
      } catch (e) {
        console.error(
          "error in adding file to zip: ",
          promise.value.filename,
          e
        );
      }
    } else {
      console.error("error in downloading file: ", promise.reason);
    }
  });

  const hasError = promises.some((download) => download.status === "rejected");

  // Download ZIP
  try {
    const zipData = await zip.generateAsync({ type: "blob" });
    triggerDownloadViaAnchor({ data: zipData, filename: zipFileName });
  } catch (error) {
    console.error("error in zipping: ", error);
    console.groupEnd();
  }

  console.groupEnd();
  return hasError;
};
