import { VideoIdeasInput } from "@/../__generated__/globalTypes";
import { Cart_cart_Cart_items } from "@/api/cart/__generated__/Cart";
import { VideoIdeas_videoIdeas_VideoIdeaResults_results } from "@/api/video/__generated__/VideoIdeas";
import { VideoIdea_videoIdea_VideoIdea } from "@/api/videoIdea/__generated__/VideoIdea";
import { gTagEvent, gtagSearchTermFormatter } from "@/gtag";
import { metaPixelTrackEvent } from "@/metaPixel";
import { tiktokPixelTrackEvent } from "@/tiktokPixelAnalytics";
import {
  GA_ACTIONS,
  GA_CATEGORY,
  GA_KYC_STEP_LABELS,
  GA_CUSTOM_DIMENSIONS,
} from "@/shared/utils/gtagConstants";
import { compactObject } from "@/shared/utils/object";
import omit from "lodash/omit";

/**
 * A utility file that contains different tracking methods and
 * send corresponding events to multiple analytics services, such as
 * Google Analytics, Facebook/Meta Pixel, and Tiktok Pixel
 */

// ===== Authentication Analytics =====

export const analyticsTrackLogin = () => {
  gTagEvent(GA_ACTIONS.LOGIN_GOOGLE, {
    event_category: GA_CATEGORY.AUTHENTICATION,
    event_label: "Login with Google",
    method: "google",
  });
};

export const analyticsTrackLogout = () => {
  gTagEvent(GA_ACTIONS.LOGOUT, {
    event_category: GA_CATEGORY.AUTHENTICATION,
    event_label: "Logout",
  });
};

// ===== Video Analytics =====

export const analyticsTrackVideoSearch = (input: VideoIdeasInput) => {
  gTagEvent(GA_ACTIONS.SEARCH, {
    search_term: JSON.stringify(
      gtagSearchTermFormatter(compactObject(omit(input, "pagination")))
    ),
  });
};

export const analyticsTrackVideoGalleryTerm = (
  term: string,
  videoData?: { id?: string; title?: string }
) => {
  if (videoData && videoData.title) {
    return gTagEvent(GA_ACTIONS.VIDEO_GALLERY_TERM_CLICK, {
      [GA_CUSTOM_DIMENSIONS.video_gallery_term]: term,
      event_label: ` Video Gallery: Video ${videoData?.title} viewed from "${term}"`,
      content_id: videoData?.id ?? "",
      content_name: videoData.title,
      content_type: "product",
    });
  }

  gTagEvent(GA_ACTIONS.VIDEO_GALLERY_TERM_CLICK, {
    [GA_CUSTOM_DIMENSIONS.video_gallery_term]: term,
    event_label: `Video Gallery: Clicked "${term}."`,
  });
};

export const analyticsTrackVideoSearchLoadMore = (input: VideoIdeasInput) => {
  gTagEvent(GA_ACTIONS.SEARCH_LOAD_MORE, {
    search_term: JSON.stringify(
      gtagSearchTermFormatter(compactObject(omit(input, "pagination")))
    ),
  });
};

// ===== KYC Analytics =====

export const analyticsTrackKycStart = () => {
  gTagEvent(GA_ACTIONS.KYC_START, {
    event_category: GA_CATEGORY.ONBOARDING,
    event_label: "kyc start",
  });
};

export const analyticsTrackKycContinue = (currentStepIndex: number) => {
  gTagEvent(GA_ACTIONS.KYC_CONTINUE, {
    event_category: GA_CATEGORY.ONBOARDING,
    event_label: GA_KYC_STEP_LABELS?.[currentStepIndex],
  });
};

export const analyticsTrackKycSkip = (currentStepIndex: number) => {
  gTagEvent(GA_ACTIONS.KYC_SKIP, {
    event_category: GA_CATEGORY.ONBOARDING,
    event_label: `kyc step ${currentStepIndex} skipped`,
  });
};

export const analyticsTrackKycFinish = () => {
  gTagEvent(GA_ACTIONS.KYC_FINISH, {
    event_category: GA_CATEGORY.ONBOARDING,
    event_label: "kyc finish",
  });
};

// ===== Cart Analytics =====

export const analyticsTrackCartClear = (
  cartList: (Cart_cart_Cart_items | null)[]
) => {
  gTagEvent(GA_ACTIONS.CART_CLEAR, {
    event_category: GA_CATEGORY.ECOMMERCE,
    event_label: "Cart cleared",
    items: cartList.map((item) => ({
      id: item?.videoIdea.id,
      name: item?.videoIdea.title,
    })),
    value: "01",
  });
};

export const analyticsTrackCartAdd = (
  videoIdea:
    | VideoIdeas_videoIdeas_VideoIdeaResults_results
    | VideoIdea_videoIdea_VideoIdea
) => {
  // Google
  gTagEvent(GA_ACTIONS.CART_ADD_ITEM, {
    event_category: GA_CATEGORY.ECOMMERCE,
    event_label: `Video ${videoIdea.title} added to cart`,
    content_type: "product",
    items: [
      {
        id: videoIdea.id,
        name: videoIdea.title,
        quantity: 1,
      },
    ],
    value: "01",
  });

  // Meta Pixel
  metaPixelTrackEvent("track", "AddToCart", {
    content_ids: [videoIdea.id],
    content_name: videoIdea.title ?? "",
    content_type: "product",
  });

  // Tiktok Pixel
  tiktokPixelTrackEvent("AddToCart", {
    content_id: videoIdea.id,
    content_name: videoIdea.title ?? "",
    content_type: "product",
  });
};

export const analyticsTrackCartRemove = (videoIdeaId, videoTitle: string) => {
  gTagEvent(GA_ACTIONS.CART_DELETE_ITEM, {
    event_category: GA_CATEGORY.ECOMMERCE,
    event_label: `Video ${videoTitle} removed from cart`,
    content_type: "product",
    items: [
      {
        id: videoIdeaId,
        name: videoTitle,
        quantity: 1,
      },
    ],
    value: "01",
  });
};

export const analyticsTrackCartCheckout = (
  cartList: (Cart_cart_Cart_items | null)[],
  isKycDone = true
) => {
  // Google
  gTagEvent(GA_ACTIONS.CART_CHECKOUT_START, {
    event_category: GA_CATEGORY.ECOMMERCE,
    event_label: isKycDone
      ? "Cart checkout"
      : "Cart checkout then continue with KYC",
    items: cartList.map((item) => ({
      id: item?.videoIdea.id,
      name: item?.videoIdea.title,
    })),
    value: "01",
  });

  // Tiktok Pixel
  tiktokPixelTrackEvent("InitiateCheckout");
};

export const analyticsTrackCartCheckoutFinish = () => {
  gTagEvent(GA_ACTIONS.CART_CHECKOUT_FINISH, {
    event_category: GA_CATEGORY.ECOMMERCE,
    event_label: "Cart checkout finish",
    value: "01",
  });

  // Tiktok Pixel
  tiktokPixelTrackEvent("PlaceAnOrder");
};

// ===== Subscription Plan Analytics =====

export const analyticsTrackSubscriptionPlanAdd = (newPlanName: string) => {
  gTagEvent(GA_ACTIONS.SUBSCRIPTION_ADD, {
    event_category: GA_CATEGORY.SUBSCRIPTION,
    event_label: `Subscription plan ${newPlanName} selected`,
  });
};

export const analyticsTrackSubscriptionPlanChange = (
  oldPlanName: string,
  newPlanName: string
) => {
  gTagEvent(GA_ACTIONS.SUBSCRIPTION_CHANGE, {
    event_category: GA_CATEGORY.SUBSCRIPTION,
    event_label: `Subscription changed from plan ${oldPlanName} to ${newPlanName}`,
  });
};

// ===== Video Idea Analytics =====

export const analyticsTrackVideoDetailsView = (
  videoIdea:
    | VideoIdeas_videoIdeas_VideoIdeaResults_results
    | VideoIdea_videoIdea_VideoIdea
) => {
  // Google
  gTagEvent(GA_ACTIONS.CART_VIEW_ITEM, {
    event_category: GA_CATEGORY.ECOMMERCE,
    event_label: `Video ${videoIdea.title} viewed`,
    content_type: "product",
    items: [
      {
        id: videoIdea.id,
        name: videoIdea.title,
        quantity: 1,
      },
    ],
    value: "01",
  });

  // Meta Pixel
  metaPixelTrackEvent("track", "ViewContent", {
    content_ids: [videoIdea.id],
    content_name: videoIdea.title ?? "",
    content_type: "product",
  });

  // Tiktok Pixel
  tiktokPixelTrackEvent("ViewContent", {
    content_id: videoIdea.id,
    content_name: videoIdea.title ?? "",
    content_type: "product",
  });
};

/**
 * A utility function to conditionally track revised script
 * only if new value is not equal to old one
 */
export const analyticsTrackVideoRevisedScript = (
  newScript: string,
  oldScript: string,
  videoTitle: string
) => {
  if (oldScript.trim() !== newScript.trim()) {
    gTagEvent(GA_ACTIONS.VIDEO_REVISE_SCRIPT, {
      event_category: GA_CATEGORY.ECOMMERCE,
      event_label: `Video ${videoTitle} revised script`,
      content_type: "product",
      items: [
        {
          id: videoTitle,
          name: videoTitle,
          quantity: 1,
        },
      ],
      value: "01",
    });
  }
};

// TODO: Can be removed in the future once it's certain that the feature won't be re-used. See: #865d825tt #865cxfbj5
/** @deprecated */
export const analyticsTrackPublicCreatorLead = (options: {
  performerId: string;
  performerName?: string;
  referrer?: string;
}) => {
  gTagEvent(GA_ACTIONS.PUBLIC_CREATOR_LEAD, {
    [GA_CUSTOM_DIMENSIONS.creator_id]: options.performerId,
    event_label: `Top Creators (Public): Clicked ${options.performerName} "Hire" lead button`,
    page_referrer: options.referrer || document.referrer,
  });
};
