import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { subscribeToNewsletterGql } from "@/api/newsletter/subscribeToNewsletter";
import {
  SubscribeToNewsletter,
  SubscribeToNewsletterVariables,
  SubscribeToNewsletter_subscribeToNewsletter_GenericSuccess,
} from "@/api/newsletter/__generated__/SubscribeToNewsletter";
import { i18nTranslate } from "@/plugins/i18n";
import message from "@/shared/utils/message";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { HideIfErrorCode } from "../utils/graphql/errorHandler";

export const useNewsletter = () => {
  const {
    mutate: subscribeToNewsletterMutate,
    loading: subscribeToNewsletterLoading,
  } = useCustomMutation<SubscribeToNewsletter, SubscribeToNewsletterVariables>(
    subscribeToNewsletterGql
  );

  const subscribeToNewsletter = async (
    input: SubscribeToNewsletterVariables
  ) => {
    const response = await subscribeToNewsletterMutate(input);

    const parsedResponse =
      parseGqlResponse<SubscribeToNewsletter_subscribeToNewsletter_GenericSuccess>(
        "GenericSuccess",
        response,
        HideIfErrorCode.ALL_ERRORS
      );

    if (parsedResponse.error) {
      throw parsedResponse.error;
    }

    if (parsedResponse.data) {
      if (parsedResponse.data.success) {
        message["success"]({
          content:
            parsedResponse.data.message ||
            i18nTranslate(
              "You have successfully subscribed to our newsletter!"
            ),
        });
      }
    }

    return parsedResponse;
  };

  return { subscribeToNewsletter, subscribeToNewsletterLoading };
};
