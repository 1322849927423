import gql from "graphql-tag";

export const submitCreatorContentGql = gql`
  mutation SubmitCreatorContent(
    $input: SubmitCreatorContentInput!
    $photo: Upload
    $video: Upload
  ) {
    submitCreatorContent(input: $input, photo: $photo, video: $video) {
      ... on CreatorContentSubmission {
        __typename
        id
        created
        modified
        contentType
        photo
        brand
        brandInstagramUrl
        creatorName
        creatorInstagramUrl
        phoneNumber
        link
        email
        photoPrice
        videoPrice
        metadata
        video
      }

      ... on ResponseErrors {
        errors {
          code
          displayMessage
          message
        }
      }
    }
  }
`;
