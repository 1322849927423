import { RouteRecordRaw } from "vue-router";

/* eslint-disable */
// @ts-ignore
import devToolsRoutes from "./devToolsRoutes";
import routeNames from "@/web/router/routeNames";
/* eslint-enable */

/**
 * Note: Add `shared` prefix to webpackChunkName to prevent overlap with other routes
 * (e.g. sharedHome)
 */
const sharedRoutes: Array<RouteRecordRaw> = [
  ...devToolsRoutes,
  /**
   * For google login redirect URI,
   * so it won't be considered unknown route (and get redirected below in catchAll)
   *
   * See: `VUE_APP_AUTH_GOOGLE_REDIRECT_URI`
   */
  {
    path: "/authorization/google",
    component: () =>
      import(
        /* webpackChunkName: "sharedGoogleLoginRedirectLoading" */ "../views/GoogleLoginRedirectLoading.vue"
      ),
  },
  {
    name: routeNames.shortLinkRedirect,
    path: "/:catchAll(.*)",
    component: () =>
      import(
        /* webpackChunkName: "sharedShortenedLinkRedirect" */ "../views/ShortenedLinkRedirect.vue"
      ),
  },

  /// FIXME: Enable if needed. Currently, preferred behavior is to redirect to home on invalid URLs.
  /// Redirection happens in ShortenedLinkRedirect.vue.
  /// ^ This component checks first in BE if the URL is a valid short link.
  // {
  //   path: "/404",
  //   component: () =>
  //     import(/* webpackChunkName: "sharedNotFound" */ "../views/NotFound.vue"),
  //   redirect: (to) => {
  //     console.error("Tried to access invalid route: ", to.fullPath);

  //     return { name: routeNames.home };
  //   },
  // },
];

export default sharedRoutes;
