import { logRocketInit, logRocketIdentify } from "./shared/utils/logrocket";
import app from "@/web/entryApp";
import { gTagInit } from "./gtag";
import store from "@/web/store/index";

logRocketInit();
gTagInit(app);
logRocketIdentify(store.state.auth.userId);

export default app;
