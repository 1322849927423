import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex gap-6 items-center" }
const _hoisted_2 = {
  key: 1,
  class: "flex gap-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrandIcon = _resolveComponent("BrandIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ShoppingCartOutlined = _resolveComponent("ShoppingCartOutlined")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_MenuOutlined = _resolveComponent("MenuOutlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!

  return (_openBlock(), _createBlock(_component_a_layout_header, { class: "layout-creator-content-header flex items-center justify-between" }, {
    default: _withCtx(() => [
      _createVNode(_component_router_link, {
        to: { name: _ctx.routeNames.home },
        class: "layout-creator-content-header__icon_container"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BrandIcon)
        ]),
        _: 1
      }, 8, ["to"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          to: { name: _ctx.routeNames.creatorContentCart },
          class: "layout-creator-content-header__cart"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ShoppingCartOutlined),
            _createVNode(_component_a_badge, { count: _ctx.cartItemCount }, null, 8, ["count"])
          ]),
          _: 1
        }, 8, ["to"]),
        (_ctx.isMobile)
          ? (_openBlock(), _createBlock(_component_a_dropdown, {
              key: 0,
              "overlay-class-name": "layout-creator-content-header__dropdown layout-creator-content-header__dropdown--mobile"
            }, {
              overlay: _withCtx(() => [
                _createVNode(_component_a_menu, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_menu_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, {
                          to: { name: _ctx.routeNames.creatorContentSubmit }
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("Submit Your Content")), 1)
                          ]),
                          _: 1
                        }, 8, ["to"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, {
                          to: { name: _ctx.routeNames.home }
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("Order UGC/Custom Videos")), 1)
                          ]),
                          _: 1
                        }, 8, ["to"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("a", {
                  onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
                }, [
                  _createVNode(_component_MenuOutlined)
                ])
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_router_link, {
                to: { name: _ctx.routeNames.creatorContentSubmit }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Submit Your Content")), 1)
                ]),
                _: 1
              }, 8, ["to"]),
              _createVNode(_component_router_link, {
                to: { name: _ctx.routeNames.home }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Order UGC/Custom Videos")), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ]))
      ])
    ]),
    _: 1
  }))
}