import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutCreatorContentHeader = _resolveComponent("LayoutCreatorContentHeader")!
  const _component_LayoutNonAuthenticatedHeader = _resolveComponent("LayoutNonAuthenticatedHeader")!
  const _component_LayoutAuthenticatedHeader = _resolveComponent("LayoutAuthenticatedHeader")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_LayoutFooter = _resolveComponent("LayoutFooter")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, {
    class: _normalizeClass(["authenticated-layout", [_ctx.$route.meta.noHeader && 'no-header']])
  }, {
    default: _withCtx(() => [
      (_ctx.$route.meta.customHeader)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.$route.meta.customHeader !== _ctx.HeaderTypes.NO_HEADER)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_ctx.$route.meta.customHeader === _ctx.HeaderTypes.CREATOR_CONTENT)
                    ? (_openBlock(), _createBlock(_component_LayoutCreatorContentHeader, { key: 0 }))
                    : (_ctx.$route.meta.customHeader === _ctx.HeaderTypes.NON_AUTH)
                      ? (_openBlock(), _createBlock(_component_LayoutNonAuthenticatedHeader, { key: 1 }))
                      : (_ctx.$route.meta.customHeader === _ctx.HeaderTypes.AUTH)
                        ? (_openBlock(), _createBlock(_component_LayoutAuthenticatedHeader, { key: 2 }))
                        : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ], 64))
        : (_openBlock(), _createBlock(_component_LayoutAuthenticatedHeader, { key: 1 })),
      _createVNode(_component_a_layout_content, { class: "authenticated-layout__content" }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }),
      _createVNode(_component_LayoutFooter)
    ]),
    _: 3
  }, 8, ["class"]))
}