
import routeNames from "@/web/router/routeNames";
import { defineComponent } from "vue";
import BrandIcon from "@/shared/components/Icons/BrandIcon.vue";
import { useI18n } from "vue-i18n";
import { useWindowSizeChecker } from "@/shared/utils/windowSizeChecker";
import QuestionCircleOutlined from "@ant-design/icons-vue/QuestionCircleOutlined";
import { useGlobalModal } from "@/shared/composables/useGlobalModal";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const { isMobile } = useWindowSizeChecker();

    const { openGlobalModal } = useGlobalModal("HOW_TO_ORDER");

    return {
      t,
      routeNames,
      isMobile,
      openGlobalModal,
    };
  },
  components: { BrandIcon, QuestionCircleOutlined },
});
