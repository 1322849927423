import {
  SubmitCreatorContent,
  SubmitCreatorContentVariables,
  SubmitCreatorContent_submitCreatorContent_CreatorContentSubmission,
} from "@/api/creatorContent/__generated__/SubmitCreatorContent";
import {
  CreatorContentSubmissions,
  CreatorContentSubmissionsVariables,
  CreatorContentSubmissions_creatorContentSubmissions,
  CreatorContentSubmissions_creatorContentSubmissions_CreatorContentSubmissionResults,
} from "@/api/creatorContent/__generated__/CreatorContentSubmissions";
import {
  SubmitCreatorContentInput,
  CreatorContentSubmissionsInput,
  CheckoutCreatorContentInput,
} from "@/../__generated__/globalTypes";
import { submitCreatorContentGql } from "@/api/creatorContent/submitCreatorContent";
import { creatorContentSubmissionsGql } from "@/api/creatorContent/creatorContentSubmissions";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useQuery } from "@vue/apollo-composable";
import { Ref, computed, reactive, ref } from "vue";
import { isEmpty, get } from "lodash";
import {
  CheckoutCreatorContent,
  CheckoutCreatorContentVariables,
  CheckoutCreatorContent_checkoutCreatorContent_GenericSuccess,
} from "@/api/creatorContent/__generated__/CheckoutCreatorContent";
import { checkoutCreatorContentGql } from "@/api/creatorContent/checkoutCreatorContent";

export const DEFAULT_PAGE_SIZE = 5;

/** Creator Content Submit */
export const useSubmitCreatorContent = () => {
  const {
    mutate: submitCreatorContentMutate,
    loading: submitCreatorContentLoading,
  } = useCustomMutation<SubmitCreatorContent, SubmitCreatorContentVariables>(
    submitCreatorContentGql
  );

  const submitCreatorContentRequest = async (
    input: SubmitCreatorContentInput,
    files: {
      photo?: File;
      video?: File;
    }
  ) => {
    const response = await submitCreatorContentMutate({
      input,
      photo: files.photo,
      video: files.video,
    });

    const parsedResponse =
      parseGqlResponse<SubmitCreatorContent_submitCreatorContent_CreatorContentSubmission>(
        "CreatorContentSubmission",
        response
      );

    if (!isEmpty(parsedResponse.error?.errors) || !response) {
      throw new Error("Failed to submit request");
    }

    return parsedResponse.data;
  };

  return {
    submitCreatorContentRequest,
    submitCreatorContentLoading,
  };
};

/** Creator Content Search */
export const useCreatorContentSubmissions = (options?: {
  initialInput?: Omit<CreatorContentSubmissionsInput, "pagination">;
  pageSize?: number;
  queryActiveRef?: Ref<boolean>;
}) => {
  const initialInput = options?.initialInput ?? {};
  const pageSize = options?.pageSize ?? DEFAULT_PAGE_SIZE;

  const creatorContentSubmissionsFilter =
    reactive<CreatorContentSubmissionsInput>({
      ...initialInput,
      pagination: {
        pageSize: pageSize,
        after: null,
      },
    });

  const {
    result: creatorContentSubmissionsResult,
    loading: creatorContentSubmissionsLoading,
    fetchMore,
    refetch: creatorContentSubmissionsRefetch,
  } = useQuery<CreatorContentSubmissions, CreatorContentSubmissionsVariables>(
    creatorContentSubmissionsGql,
    () => ({ input: creatorContentSubmissionsFilter }),
    () => ({ enabled: options?.queryActiveRef?.value ?? true })
  );

  const creatorContentSubmissionsParsedResponse = computed(() => {
    return parseGqlResponse<CreatorContentSubmissions_creatorContentSubmissions_CreatorContentSubmissionResults>(
      "CreatorContentSubmissionResults",
      creatorContentSubmissionsResult.value
    );
  });

  const creatorContentSubmissions = computed(
    () => creatorContentSubmissionsParsedResponse.value.data?.results
  );

  const creatorContentSubmissionsPageInfo = computed(() => {
    return creatorContentSubmissionsParsedResponse.value.data?.pageInfo;
  });

  const creatorContentSubmissionsHasNextPage = computed(
    () => creatorContentSubmissionsPageInfo.value?.hasNextPage
  );

  const creatorContentSubmissionsFetchMoreLoading = ref(false);

  const creatorContentSubmissionsFetchMore = async () => {
    if (
      creatorContentSubmissionsPageInfo.value?.hasNextPage &&
      creatorContentSubmissionsPageInfo.value.endCursor
    ) {
      creatorContentSubmissionsFetchMoreLoading.value = true;
      await fetchMore({
        variables: {
          input: {
            ...creatorContentSubmissionsFilter,
            pagination: {
              pageSize: pageSize,
              after: creatorContentSubmissionsPageInfo.value.endCursor,
            },
          },
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const updatedCreatorContentSubmissionsResults = {
            ...previousResult.creatorContentSubmissions,
            ...fetchMoreResult?.creatorContentSubmissions,

            // Concatenate results array
            results: [
              ...get(previousResult.creatorContentSubmissions, "results", []),
              ...get(fetchMoreResult?.creatorContentSubmissions, "results", []),
            ],
          };

          return {
            creatorContentSubmissions:
              updatedCreatorContentSubmissionsResults as CreatorContentSubmissions_creatorContentSubmissions,
          };
        },
      });
      creatorContentSubmissionsFetchMoreLoading.value = false;
    }
  };

  return {
    creatorContentSubmissions,
    creatorContentSubmissionsLoading,
    creatorContentSubmissionsFetchMore,
    creatorContentSubmissionsFetchMoreLoading,
    creatorContentSubmissionsHasNextPage,
    creatorContentSubmissionsRefetch,
    creatorContentSubmissionsFilter,
  };
};

/** Creator Content Checkout */
export const useCheckoutCreatorContent = () => {
  const {
    mutate: checkoutCreatorContentMutate,
    loading: checkoutCreatorContentLoading,
  } = useCustomMutation<
    CheckoutCreatorContent,
    CheckoutCreatorContentVariables
  >(checkoutCreatorContentGql);

  const checkoutCreatorContent = async (input: CheckoutCreatorContentInput) => {
    const response = await checkoutCreatorContentMutate({ input });

    const parsedResponse =
      parseGqlResponse<CheckoutCreatorContent_checkoutCreatorContent_GenericSuccess>(
        "GenericSuccess",
        response
      );

    if (!isEmpty(parsedResponse.error?.errors) || !response) {
      throw new Error("Failed to checkout request");
    }

    return parsedResponse.data;
  };

  return {
    checkoutCreatorContent,
    checkoutCreatorContentLoading,
  };
};
