import { ApolloLink } from "@apollo/client/core";
import { onError } from "apollo-link-error";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { makeToast } from "@/shared/utils/toast";
import { i18nTranslate } from "@/plugins/i18n";
import store from "@/web/store";
import useLogging from "@/shared/composables/useLogging";
import { wsClientContainer } from "./wsLink";
import webRouter from "@/web/router";
import routeNames from "@/web/router/routeNames";
import isEmpty from "lodash/isEmpty";
import { HideIfErrorCode } from "@/shared/utils/graphql/errorHandler";
import { createRedirectedRoute } from "@/shared/utils/routerUtils";
import { useApolloClient } from "@vue/apollo-composable";

/**
 * Update this for to include all [__typename] for all errors that we
 * need to logout the user. For available [__typename] for all errors refer to the schema
 */
const errorTypeNameToLogout = ["AuthenticationError"];

export const customErrorHandlerLink = new ApolloLink((operation, forward) => {
  const { submitInfoToApi } = useLogging();
  return forward(operation).map((data) => {
    const { client } = useApolloClient();

    const parsedGqlResponse = parseGqlResponse<void>(
      "",
      data?.data,
      HideIfErrorCode.ALL_ERRORS
    );

    if (!isEmpty(parsedGqlResponse?.error?.errors)) {
      /// Automatic logout based on custom error
      const shouldLogout = parsedGqlResponse?.error?.errors?.some((error) =>
        errorTypeNameToLogout.includes(error?.__typename)
      );

      if (shouldLogout && store?.state?.auth?.isAuthenticated) {
        (async () => {
          // close ws client connection
          wsClientContainer.wsClient?.close(true, true);

          await store.dispatch("logoutSuccess");

          // Clear all existing data and re-fetch for clean state (next user)
          await client.clearStore();

          // If will logout (expired token), assign the redirectTo query to this current path
          const targetRoute = webRouter.currentRoute.value;

          console.log(targetRoute);

          /// FIXME: `/` as path somehow cannot match `routePaths.home`
          /// Temporary fix, redirect to login if no route matches too.
          if (
            targetRoute.matched.length === 0 ||
            targetRoute.meta.requiresAuth === true
          ) {
            await webRouter.push(
              createRedirectedRoute(
                { name: routeNames.login },
                targetRoute.fullPath
              )
            );
          }
          // If currently in a route that does not require auth,
          else {
            // Refetch aborted queries. See: #865d55pba
            await client.reFetchObservableQueries();
          }

          submitInfoToApi(
            "SESSION_EXPIRED: Logged Out Automatically due to inactivity"
          );

          makeToast(
            "error",
            i18nTranslate("You're logged out"),
            i18nTranslate("Your session has expired. Please log in again.")
          );
        })();
      }
    }

    return data;
  });
});

export const errorHandlerLink = onError(
  ({ graphQLErrors, networkError, operation }) => {
    /**
     * Add console log, this will be available on logrocket
     */
    console.group("errorHandlerLink");
    console.log(`graphQLErrors`, !!graphQLErrors);
    console.log(`networkError`, !!networkError);
    console.log(`errorResponse`, { graphQLErrors, networkError, operation });
    console.groupEnd();

    if (graphQLErrors) {
      makeToast(
        "error",
        i18nTranslate("Error"),
        i18nTranslate("Something went wrong. Please try again later.")
      );
    } else if (networkError) {
      /**
       * If error thrown is this error again
       *
       * Do not proceed if error is "Failed to fetch"
       * - this means that the user do not have internet and logging will also fail
       */
      if (
        (networkError.message && networkError.message === "NETWORK_ERROR") ||
        (networkError.message && networkError.message === "Failed to fetch") ||
        operation.operationName === "createLog"
      ) {
        return;
      }

      makeToast(
        "error",
        i18nTranslate("Error"),
        i18nTranslate("A network error occurred, Kindly refresh and try again.")
      );
    }
  }
);
