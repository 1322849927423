import { LocalStorageKey } from "@/shared/utils/localStorage";
import throttle from "lodash/throttle";
import { Ref, ref, watch } from "vue";

export const DEFAULT_DEBOUNCE_DURATION = 2000;

/**
 * Creates and returns a reactive ref variable that is watched and saved to localStorage.
 *
 * @example
 * const count = ref(0);
 *
 * const { store, resetState } = createLocalStorageStore({
 *  defaultState: 0,
 *  // See: LocalStorageKeys
 *  key: LocalStorageKeys.COUNT_KEY,
 * });
 */
export const createLocalStorageStore = <T>(options: {
  defaultState: T;
  key: LocalStorageKey;
  saveDebounceDuration?: number;
  /** Use for non-primitive Refs (ie. objects) */
  deep?: boolean;
  onLoadCallback?: (value: T) => any;
}) => {
  /** Internal Store State based on Generic <T> */
  const store = ref<T>(options.defaultState) as Ref<T>;

  // Default Options
  const debounceDuration =
    options.saveDebounceDuration || DEFAULT_DEBOUNCE_DURATION;

  const loadState = (): T => {
    const savedStateString = window.localStorage.getItem(options.key);

    if (!savedStateString) {
      // Triggers saving
      updateValue(options.defaultState);
      return options.defaultState;
    }

    const savedState = JSON.parse(savedStateString) as T;
    updateValue(savedState);
    return savedState;
  };

  const saveState = (customState?: T) => {
    const state = customState ?? store.value ?? options.defaultState;
    const stateJson = JSON.stringify(state);
    window.localStorage.setItem(options.key, stateJson);

    console.log("[createLocalStorageStore] Saved state: ", options.key);
    console.log(stateJson);
  };

  /** Updates `store` value. Also triggers save on `localStorage` */
  const updateValue = (state: T) => {
    store.value = state;
  };

  const resetState = (newState: T) => {
    // FIXME: For some reason, `options.defaultState` is updated (does not retain passed value).
    // Workaround, pass the state in argument to create a new instance of state.
    updateValue(newState);
  };

  /**
   * Loads value from localStorage when `creatorLocalStorageStore` is called.
   */
  loadState();

  /**
   * Watch for value changes.
   * Does the actual saving to localStorage.
   */
  watch(
    store,
    throttle(() => {
      saveState();
    }, debounceDuration),
    { immediate: false, deep: options.deep }
  );

  return { resetState, store };
};
