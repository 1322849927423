import { RouteLocationNamedRaw, RouteLocationPathRaw } from "vue-router";
import isEmpty from "lodash/isEmpty";
import router from "@/web/router/index";

/**
 * Creates a new Route with `redirectTo` URL query.
 * @param route The route to add redirection.
 * @param redirectTo The path to redirect to (or another Route object)
 */
export const createRedirectedRoute = (
  route: RouteLocationPathRaw | RouteLocationNamedRaw,
  redirectTo:
    | RouteLocationPathRaw
    | RouteLocationNamedRaw
    | string
    | null
    | undefined
): RouteLocationPathRaw | RouteLocationNamedRaw => {
  if (isEmpty(redirectTo)) {
    return route;
  }

  let redirectPath = "";

  if (typeof redirectTo === "string") {
    redirectPath = redirectTo;
  } else if (typeof redirectTo === "object" && redirectTo !== null) {
    if ("path" in redirectTo) {
      redirectPath = redirectTo.path;
    } else if ("name" in redirectTo) {
      redirectPath = router.resolve(redirectTo).fullPath;
    }
  }

  return {
    ...route,
    query: {
      ...(route.query ?? {}),
      redirectTo: redirectPath,
    },
  };
};

/** Returns URL without the hostname to be used in vue-router. Returns null on invalid URL. */
export const getRelativePathFromUrl = (url: string) => {
  try {
    const urlObj = new URL(url);
    return urlObj.pathname + urlObj.search + urlObj.hash;
  } catch (e) {
    return "";
  }
};
