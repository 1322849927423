
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useGlobalModal } from "@/shared/composables/useGlobalModal";
import { config } from "@/shared/utils/config";

export default defineComponent({
  setup() {
    const { t } = useI18n();

    const { isGlobalModalVisible, openGlobalModal, closeGlobalModal } =
      useGlobalModal("LEARN_MORE");

    return {
      t,
      config,
      isGlobalModalVisible,
      openGlobalModal,
      closeGlobalModal,
    };
  },
});
