import gql from "graphql-tag";

export const authenticateViaEmailGql = gql`
  mutation AuthenticateViaEmail($input: AuthenticateViaEmailInput!) {
    authenticateViaEmail(input: $input) {
      ... on Authentication {
        user {
          id
          username
          created
          email
          created
          modified
          name
          subscriptionPlan {
            id
            name
            price
            maxVideoCount
            description
            isActive
            sortWeight
          }
          cartItemCount
        }
        authToken {
          token
          expiry
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
